import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ContentBox, Row, Column } from 'ui-library';
import PageSection from 'components/PageSection/PageSection';
import { useTranslation } from 'react-i18next';
import SearchBar from '../SearchBar';

import './ListPreview.css';

const ListPreview = ({
    search,
    filters,
    onSearch,
    titleComponent,
    listComponent,
    preSelect,
    getData,
    getPreview,
    getDataCallback,
    children,
    searchBarActive,
    dataLength,
}) => {
    const { t } = useTranslation();

    const SearchBarRender = useMemo(
        () => searchBarActive && (
            <SearchBar
                names={search}
                selected={filters}
                onSelect={onSearch}
                placeholder={t('messages.searchBarPlaceholder')}
                labelReset={t('messages.labelResetFilter')}
            />
        ),
        [searchBarActive, search, filters, onSearch, t],
    );

    return (
        <PageSection className="list-preview">
            {SearchBarRender}
            <ContentBox underline={false}>
                <Row className="list-preview-title">
                    {titleComponent}
                </Row>
                <Row className="list-preview__content">
                    <Column size={(dataLength === undefined || dataLength > 0) ? 'lg-5 min-heigth' : 'lg-12'} className="list-preview__list">
                        {listComponent({
                            getData, getPreview, getDataCallback, preSelect,
                        })}
                    </Column>
                    {(dataLength === undefined || dataLength > 0) && (
                        <Column size="lg-7 preview-container">
                            {children}
                        </Column>
                    )}
                </Row>
            </ContentBox>
        </PageSection>
    );
};

ListPreview.propTypes = {
    search: PropTypes.arrayOf(PropTypes.string),
    filters: PropTypes.arrayOf(PropTypes.string),
    onSearch: PropTypes.func,
    children: PropTypes.node,
    preSelect: PropTypes.number,
    listComponent: PropTypes.func.isRequired,
    titleComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    searchBarActive: PropTypes.bool,
    getData: PropTypes.func,
    getPreview: PropTypes.func,
    getDataCallback: PropTypes.func,
    dataLength: PropTypes.number,
};

ListPreview.defaultProps = {
    search: [],
    filters: [],
    preSelect: undefined,
    children: null,
    searchBarActive: false,
    getData: () => {},
    onSearch: () => {},
    getPreview: () => {},
    getDataCallback: () => {},
    dataLength: undefined,
};

export default ListPreview;
