import { useCallback } from 'react';
import ServiceManager from 'services/ServiceManager';
import { useSelector } from 'react-redux';
import { contactIdSelector } from 'redux/auth/authSelectors';
import history from 'services/history';
import { AxiosResponse } from 'axios';
import { ResultResponseData } from 'additiv-services/dist/types/services/security';
import { usePasswordManipulations } from './usePasswordManipulations';
import { PasswordChangeFlow, ResetPasswordHookReturn } from '../types';
import { validate } from '../validators/changePasswordValidation';


export const useChangePassword = ():ResetPasswordHookReturn => {
    const contactId = useSelector(contactIdSelector);
    const passwordManipulationsData = usePasswordManipulations({
        validate,
        serviceMethod: ():Promise<AxiosResponse<ResultResponseData>> => (
            ServiceManager.Security(
                'changePasswordByContactIdWithPolicies',
                [{
                    urlParams: { contactId },
                    payload: {
                        Password: passwordManipulationsData.password,
                        ConfirmPassword: passwordManipulationsData.confirmPassword,
                        OldPassword: passwordManipulationsData.oldPassword,
                    },
                }],
            )
        ),
    });

    const onBack = useCallback(() => {
        history.goBack();
    }, []);

    return {
        ...passwordManipulationsData,
        processType: PasswordChangeFlow.CHANGE_PASSWORD,
        resetView: {
            title: 'changePassword.passwordCreation',
            description: 'changePassword.enterNewPassword',
            backURLButtonLabel: 'changePassword.cancel',
        },
        successView: {
            title: 'changePassword.newPasswordIsSet',
            description: 'changePassword.passwordIsReadyToUse',
            backURLButtonLabel: 'changePassword.backURL',
            onBack,
        },
    };
};
