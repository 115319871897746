import {
    Title, Button, Column, Row, Paragraph,
} from 'ui-library';
import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    DISCRETIONARY, generateObject, ADVISORY, DEPOSITS, EXECUTION_ONLY,
} from 'constants/portfolioProducts';
import history from 'services/history';
import Preloader from 'components/Preloader';
import { useKnowledgeExperienceDetails } from '../../hooks/useKnowledgeExperienceDetails';
import './KnowledgeExperienceDetails.css';

const KnowledgeExperienceDetails = (props) => {
    const { clientId } = props;
    const { data, isLoading, error } = useKnowledgeExperienceDetails({ clientId });
    const { t } = useTranslation();

    const onExpand = (item) => {
        const state = {
            productId: item.productId,
            productName: item.productName,
            values: item.answers?.reduce(
                (acc, i) => (i?.Values?.length ? ([...acc, ...i?.Values]) : acc), [],
            ),
            answers: item.answers,
        };

        history.push('/knowledge-experience/0', state);
    };

    const renderAnswersListWithButton = useCallback((item) => (
        <>
            <div className="answers-list">
                <Paragraph type="secondary">
                    <span>
                        {`${t('details.youAreEligibleFor')}: `}
                    </span>
                    <span>
                        {(item.answers?.[0]?.Answers || []).map(
                            (answer) => answer?.Text,
                        ).join(', ')}
                    </span>
                </Paragraph>
            </div>
            {!item.answers?.[0]?.allAnswered && (
                <Button type="secondary" size="small" onClick={() => onExpand(item)}>
                    {t('details.expandInstrumentUniverse')}
                </Button>
            )}
        </>
    ));

    const renderDiscretionaryConditions = useMemo(() => (
        <Paragraph type="secondary">
            {t('details.youAcceptedTheDiscretionaryProductConditions')}
        </Paragraph>
    ), []);

    const renderKEItem = useCallback((item) => generateObject({
        [ADVISORY]: renderAnswersListWithButton(item),
        [DISCRETIONARY]: renderDiscretionaryConditions,
        [EXECUTION_ONLY]: renderAnswersListWithButton(item),
        [DEPOSITS]: renderAnswersListWithButton(item),
    }).getByName(item.productName),
    [renderAnswersListWithButton, renderDiscretionaryConditions]);

    return (
        <Preloader isLoading={isLoading} error={error}>
            <Row>
                {data.map((item) => (
                    <Column key={item.productId} size={['md-7', 'sm-12']} className="details-knowledge-experience">
                        <Title type={3}>{item.productName}</Title>
                        {renderKEItem(item)}
                    </Column>
                ))}
            </Row>
        </Preloader>
    );
};

KnowledgeExperienceDetails.propTypes = {
    clientId: PropTypes.number.isRequired,
};

export default KnowledgeExperienceDetails;
