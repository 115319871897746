import React from 'react';
import { SkeletonLine } from 'ui-library';

export const adaptTableComponents = ({
    data, columns, pageSize, isLoading,
}) => {
    if (isLoading) {
        return new Array(pageSize).fill(null).map(() => (
            columns.map(({ key, render }) => {
                if (render) {
                    return ({
                        [key]: {
                            label: <SkeletonLine width="100px" />,
                            render: <SkeletonLine width="100%" />,
                        },
                    });
                }

                return ({ [key]: <SkeletonLine width="100%" /> });
            }).reduce((a, i) => ({ ...a, ...i }), {})
        ));
    }

    return data;
};
