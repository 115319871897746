import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Accordion, AccordionPanel as Panel,
} from 'ui-library';
import { useFormatting } from 'locale';
import { onBoardingDataSelector, useOnBoardingSelector } from 'domain/OnBoarding';
import { useDocuments, useInstrumentData } from 'domain/Instrument';
import { adaptInstrumentDocs } from 'adaptors/adaptInstrumentDocs';
import withInstrumentAccess from 'hocs/withInstrumentAccess';
import InstrumentDocumentsTable from 'components/InstrumentDocumentsTable';
import DetailsPerformance from 'components/PositionDetailsPerformace';
import PositionOverview from 'components/PositionOverview';
import KeyData from 'components/KeyData';
import {
    BondId, INTEGER, PERCENTAGE, StructuredProductId, FundId,
} from 'constants/instrument';
import { useFundLookThrough } from 'domain/Instrument/hooks/useFundLookThrough';
import { FundLookThrough } from 'pages/pages/Portfolios/pages/SinglePortfolioView/pages/PositionDetails/components/FundLookThrough';
import OnBoardingBaseTemplate from '../../components/OnBoardingBaseTemplate';
import { adaptPositions } from '../../adapters/adaptModifyPositions';
import './PositionDetails.css';

const PositionDetails = (props) => {
    const {
        dfsClientId,
        onPageChange,
        match: { params: { positionId } },
        location: { pathname },
        currentStep,
        isModify,
    } = props;
    const { t, i18n: { language } } = useTranslation();
    const { getFormattedCurrency, getFormattedNumber, getFormattedDate } = useFormatting();
    const params = /.+?(?=\/position)/g.exec(pathname);
    const paramLink = params?.[0]?.split('/');
    const backLink = paramLink?.[4] || '';

    // OnBoarding Domain
    const {
        getGoalDetails, modifiedPositions, saveModifiedPositions,
    } = useOnBoardingSelector(onBoardingDataSelector);

    // Instrument Domain
    const {
        data: dataDocs, isLoading: isLoadingDocs, error: errorDocs, getDocuments,
        errorDocument, getDocumentById,
    } = useDocuments({ instrumentId: positionId });
    const documents = useMemo(() => adaptInstrumentDocs({
        data: dataDocs, getFormattedDate, t, getDocumentById, positionId, language,
    }), [dataDocs, getFormattedDate, t, getDocumentById, positionId, language]);
    const {
        data, isLoading, error, getCommon,
        dataPerformance, isLoadingPerformance, errorPerformance, getPerformance,
        dataBenchmark, isLoadingBenchmark, errorBenchmark,
    } = useInstrumentData({ instrumentId: positionId });

    const {
        data: dataFLT,
        isLoading: isLoadingFLT,
        error: errorFLT,
        changePage: changePageFLT,
    } = useFundLookThrough({
        instrumentId: positionId,
        isOnboarding: true,
        dfsClientId,
        language,
    });


    // Data
    const goalDetails = useMemo(() => getGoalDetails() || {}, [getGoalDetails]);
    const priceType = useMemo(() => ([BondId, StructuredProductId].includes(data.typeId)
        ? PERCENTAGE : INTEGER), [data.typeId]);
    const additionalBenchmark = useMemo(() => ({
        name: dataBenchmark.name,
        tradingPriceOfUnderlying: dataBenchmark.tradingPrice,
        calculatedRiskOfUnderlying: dataBenchmark.risk,
    }), [dataBenchmark]);
    const positionLink = useMemo(() => `/onboarding/step/${currentStep}/modify/position`, [currentStep]);
    const optionsForAdapt = useMemo(
        () => ({
            portfolioValue: +goalDetails.initialInvestment,
            portfolioCurrency: goalDetails.selectedCurrency?.label,
            nameLength: 35,
            isNew: true,
            positionLink,
            getFormattedCurrency,
            getFormattedNumber,
            language,
            t,
        }),
        [
            goalDetails.initialInvestment,
            goalDetails.selectedCurrency,
            positionLink,
            getFormattedCurrency,
            getFormattedNumber,
            language,
            t,
        ],
    );
    const positionToSave = useMemo(() => data.id && adaptPositions(
        [{ Security: data, Allocation: 0 }], optionsForAdapt,
    ), [data, optionsForAdapt]);

    // Effects
    useEffect(() => {
        getCommon();
    }, [getCommon]);
    useEffect(() => {
        if (data.currency?.id) getPerformance({ currencyId: data.currency?.id });
    }, [data.currency, getPerformance]);
    useEffect(() => {
        if (data.uId) getDocuments({ instrumentUId: data.uId });
    }, [data.uId, getDocuments]);

    // Callbacks
    const handlePrev = () => {
        if (isModify) {
            onPageChange('add-position');
        } else {
            onPageChange(backLink);
        }
    };
    const handleNext = () => {
        const isInModified = (modifiedPositions?.positions || [])
            .find(({ id }) => id === positionToSave?.[0]?.id);
        const isPositionExisted = isInModified?.children?.find(({ id }) => (
            id === positionToSave?.[0]?.children?.[0].id
        ));
        const newModifiedRaw = () => {
            if (modifiedPositions?.raw?.length && !isPositionExisted) {
                return [...modifiedPositions?.raw, { Id: data.id, Allocation: 0 }];
            }
            if (modifiedPositions?.raw?.length && isPositionExisted) {
                return [...modifiedPositions?.raw];
            }

            return [{ Id: data.id, Allocation: 0 }];
        };
        const newModified = isInModified
            ? modifiedPositions.positions.map((group) => ({
                ...group,
                children: [
                    ...group.children,
                    ...positionToSave?.[0]?.id === group.id && !isPositionExisted
                        ? positionToSave?.[0]?.children
                        : [],
                ],
            }))
            : [...(modifiedPositions?.positions || []), ...positionToSave];

        saveModifiedPositions({ positions: newModified, raw: newModifiedRaw() });
        onPageChange('modify');
    };

    return (
        <OnBoardingBaseTemplate
            title={data.name}
            error={error || errorBenchmark}
            isLoading={isLoading || isLoadingBenchmark || isLoadingPerformance}
            className="goal-review position-overview"
            prevButton={{
                onClick: handlePrev,
            }}
            nextButton={isModify && {
                text: t('onBoarding.add'),
                onClick: handleNext,
            }}
        >
            <Accordion defaultActiveKey={['1', '2', '3', '5']}>
                <Panel header={t('position.overview')} key="1">
                    <PositionOverview
                        data={data}
                        priceType={priceType}
                        positionId={positionId}
                        performance={dataPerformance}
                        hideHelpText
                    />
                </Panel>
                <Panel header={t('position.performance')} key="2" className="performance">
                    <DetailsPerformance
                        positionId={positionId}
                        currencyId={data.currency?.id}
                        currency={data.currency?.isoCode}
                        priceType={priceType}
                        isPortfolioProposed
                        error={errorPerformance}
                    />
                </Panel>
                <Panel header={t('position.keyData')} key="3" className="key-data">
                    <KeyData
                        positionId={positionId}
                        additionalData={{
                            ...additionalBenchmark,
                            calculatedRisk: dataPerformance?.Volatility,
                            calculatedExpectedReturn: dataPerformance?.ExpectedReturn,
                            sharpeRatio: dataPerformance?.SharpeRatio,
                        }}
                    />
                </Panel>
                {data.typeId === FundId && (
                    <Panel header={t('position.fundLookThrough')} key="5" className="table-without-last-row-padding table-without-head-row-padding">
                        <FundLookThrough
                            data={dataFLT}
                            isLoading={isLoadingFLT}
                            error={errorFLT}
                            changePage={changePageFLT}
                        />
                    </Panel>
                )}
                <Panel header={t('position.documents')} key="4" className="documents table-without-last-row-padding table-without-head-row-padding">
                    <InstrumentDocumentsTable
                        data={documents}
                        error={errorDocs}
                        isLoading={isLoadingDocs}
                        errorDocument={errorDocument}
                    />
                </Panel>
            </Accordion>
        </OnBoardingBaseTemplate>
    );
};

PositionDetails.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            positionId: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    currentStep: PropTypes.number.isRequired,
    isModify: PropTypes.bool,
    onPageChange: PropTypes.func,
    dfsClientId: PropTypes.string.isRequired,
};

PositionDetails.defaultProps = {
    isModify: false,
    onPageChange: () => { },
};

export default withInstrumentAccess(PositionDetails);
