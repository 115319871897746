import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { PortfolioOverviewProvider } from 'domain/Portfolio';
import { useSelector } from 'react-redux';
import { contactIdSelector } from 'redux/auth/authSelectors';
import { ClarityProvider } from 'domain/Clarity';
import PositionDetailsOverview from './pages/SinglePortfolioView/pages/PositionDetails/PositionDetailsOverview';
import SinglePortfolioView from './pages/SinglePortfolioView/PortfolioOverview';
import PositionDetails from './pages/SinglePortfolioView/pages/PositionDetails/PositionDetails';
import ProposalDetails from './pages/SinglePortfolioView/pages/ProposalDetails/ProposalDetails';
import ProposalDetailedOrders from './pages/SinglePortfolioView/pages/ProposalDetails/DetailedOrders';
import ProposalRejectSuccess from './pages/SinglePortfolioView/pages/ProposalDetails/ProposalRejectSuccess';
import FundingInformation from './pages/FundingInformation/FundingInformation';
import PendingOrders from './pages/PendingOrders/PendingOrders';
import withPendingOrdersAccess from './hocs/withPendingOrdersAccess';
import withFundingInformationAccess from './hocs/withFundingInformationAccess';
import withPortfolioAccess from './hocs/withPortfolioAccess';
import { adaptCashFlowsWithInflowsAndOutflows } from '../../../domain/Portfolio/adapters/adaptCashFlowsWithInflowsAndOutflows';
import { CommonAllocationProvider } from './context/CommonAllocationDataProvider';

const PortfolioRouter = (props) => {
    const {
        match: {
            path,
            params: { portfolioId },
        },
    } = props;
    const clientId = useSelector(contactIdSelector);

    return (
        <PortfolioOverviewProvider
            options={{
                id: Number(portfolioId),
                clientId: Number(clientId),
                portfolio: { loadInitially: true },
                cashFlows: {
                    adaptData: adaptCashFlowsWithInflowsAndOutflows,
                },
            }}
        >
            <CommonAllocationProvider>
                <ClarityProvider>
                    <Switch>
                        <Route exact path={`${path}`} component={withPortfolioAccess(SinglePortfolioView)} />
                        <Route
                            exact
                            path={`${path}/position/:productId/:positionId/:tabId`}
                            component={PositionDetails}
                        />
                        <Route
                            exact
                            path={`${path}/position/:productId/:positionId/:tabId/overview`}
                            component={PositionDetailsOverview}
                        />
                        <Route
                            exact
                            path={`${path}/position/:productId/:positionId/:tabId/:extraTabId`}
                            component={PositionDetails}
                        />
                        <Route
                            exact
                            path={`${path}/proposal/:proposalId`}
                            component={ProposalDetails}
                        />
                        <Route
                            exact
                            path={`${path}/proposal/:proposalId/position/:productId/:positionId/:tabId`}
                            component={PositionDetailsOverview}
                        />
                        <Route
                            exact
                            path={`${path}/proposal/:proposalId/orders`}
                            component={ProposalDetailedOrders}
                        />
                        <Route
                            exact
                            path={`${path}/proposal/:proposalId/reject-success`}
                            component={ProposalRejectSuccess}
                        />
                        <Route
                            exact
                            path={`${path}/funding-information`}
                            component={withFundingInformationAccess(FundingInformation)}
                        />
                        <Route
                            exact
                            path={`${path}/pending-orders`}
                            component={withPendingOrdersAccess(PendingOrders)}
                        />
                        <Redirect from="*" to="/404" />
                    </Switch>
                </ClarityProvider>
            </CommonAllocationProvider>
        </PortfolioOverviewProvider>
    );
};

PortfolioRouter.propTypes = {
    match: PropTypes.shape({
        path: PropTypes.string.isRequired,
        params: PropTypes.shape({
            portfolioId: PropTypes.string.isRequired,
        }),
    }).isRequired,
};

PortfolioRouter.defaultProps = {};

PortfolioRouter.displayName = 'Router';

export default PortfolioRouter;
