import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { onBoardingDataSelector, useOnBoardingSelector } from 'domain/OnBoarding';
import { EXECUTION_ONLY_ID } from 'constants/portfolioProducts';

const missingStep = ({
    missingProduct: 'product',
    missingRiskCategory: 'riskAppetite',
    missingGoalDetails: 'goal',
    missingCreatedGoal: 'review',
    missingPortfolioSetup: 'portfolioSetup',
    missingKnowledgeAndExp: 'knowledgeExperience',
});
const missingStepNumbers = ({
    missingProductOffer: 1,
    missingProduct: 1,
    missingRiskCategory: 2,
    missingKnowledgeAndExp: 3,
    missingGoalDetails: 4,
    missingPortfolioSetup: 4,
    missingCreatedGoal: 5,
});

export const useStepControl = ({ step, changeStep }) => {
    const isLastStep = step === 6;
    const [isLoading, setLoading] = useState(false);
    const [missingDataKey, setMissingDataKey] = useState(null);
    const [haveMissingData, setHaveMissingData] = useState(false);
    const [missingRiskCategory, setMissingRiskCategory] = useState(false);

    const {
        product, getGoalDetails,
        createdGoal, productOffer, knowledgeAndExperience, riskCategory,
    } = useOnBoardingSelector(onBoardingDataSelector);

    const goalDetails = useMemo(() => getGoalDetails() || {}, [getGoalDetails, step]);

    const chosenProduct = useMemo(() => product?.id || createdGoal?.ProductId,
        [product?.id, createdGoal?.ProductId, step]);
    const missingProductOffer = useMemo(() => !productOffer?.id,
        [product, step]);
    const missingProduct = useMemo(() => !product?.id,
        [product, step]);
    const missingKnowledgeAndExp = useMemo(
        () => !knowledgeAndExperience?.hasPositiveInstruments,
        [knowledgeAndExperience?.hasPositiveInstruments, step],
    );
    const missingGoalDetails = useMemo(() => Object.values(goalDetails).length === 0,
        [goalDetails, step]);
    const missingCreatedGoal = useMemo(() => Object.values(createdGoal).length === 0,
        [createdGoal, step]);
    const missingStepNumber = useMemo(() => missingStepNumbers[missingDataKey] || step,
        [missingDataKey, step]);
    const checkRiskCategory = useCallback(async () => {
        setLoading(true);
        setMissingRiskCategory(Object.values(riskCategory || {}).length === 0);
        setLoading(false);
    }, [riskCategory, setMissingRiskCategory, setLoading]);

    // Effects
    useEffect(() => {
        if (EXECUTION_ONLY_ID.includes(+chosenProduct)) {
            setMissingRiskCategory(false);
            setLoading(false);
        }
    }, [chosenProduct, checkRiskCategory]);
    useEffect(() => {
        if (!EXECUTION_ONLY_ID.includes(+chosenProduct) && step === 3) checkRiskCategory();
    }, [chosenProduct, step, checkRiskCategory]);

    useEffect(() => {
        if (isLastStep) {
            if (missingCreatedGoal) {
                setHaveMissingData(missingCreatedGoal);
                setMissingDataKey('missingCreatedGoal');
            }
        } else if (missingGoalDetails && EXECUTION_ONLY_ID.includes(+chosenProduct)
            && !missingKnowledgeAndExp) {
            setHaveMissingData(missingGoalDetails);
            setMissingDataKey('missingPortfolioSetup');
        } else {
            setHaveMissingData(
                missingProductOffer
                || missingProduct
                || missingRiskCategory
                || missingKnowledgeAndExp
                || missingGoalDetails,
            );
            const missingData = {
                missingProductOffer,
                missingProduct,
                missingRiskCategory,
                missingKnowledgeAndExp,
                missingGoalDetails,
            };

            console.log('missingData', missingData);

            const firstMissingData = Object.keys(missingData).find((key) => missingData[key]);

            if (firstMissingData) setMissingDataKey(firstMissingData);
        }
    }, [missingProduct,
        missingProductOffer,
        missingRiskCategory,
        missingGoalDetails,
        missingCreatedGoal,
        missingKnowledgeAndExp,
        chosenProduct,
        isLastStep]);

    useEffect(() => {
        if (!chosenProduct && step > 1) changeStep(1);
    }, [chosenProduct, step, changeStep]);

    return {
        isLoading,
        haveMissingData: haveMissingData && step > missingStepNumber,
        missingStepNumber,
        missingStep: missingStep[missingDataKey],
    };
};
