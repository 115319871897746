import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import {
    AccordionPanel as Panel,
    ContentBox,
    Title,
    Modal,
    BreadCrumbs,
    Accordion, Infobox,
} from 'ui-library';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import history from 'services/history';
import { useTranslation } from 'react-i18next';
import { contactIdSelector } from 'redux/auth/authSelectors';
import Preloader from 'components/Preloader';
import { NA } from 'utils/formatting';
import Analysis from 'components/Analysis/Analysis';
import ButtonsBlockRow from 'components/ButtonsBlockRow';
import { portfolioSelector, usePortfolioSelector, proposalSelector } from 'domain/Portfolio';
import { groupComparedPositions } from 'adaptors/groupComparedPositions';
import { useFormatting } from 'locale';
import AccordionWrapper from 'components/AccordionWrapper';
import RiskDisclaimer from 'components/RiskDisclaimer';
import RiskBandwidthDisclaimer from 'components/RiskBandwidthDisclaimer';
import { useBenchmarks } from 'hooks/useBenchmarks';
import { useAnalysisDetails } from 'hooks/useAnalysisDetails';
import Positions from 'components/PositionsCompare';
import { useClientProfile } from 'prodivers/clientProfile';
import { AllocationCompareWithITL } from 'components/AllocationCompareWithITL/AllocationCompareWithITL';
import Overview from './components/Overview';
import { PROPOSAL } from '../PositionDetails/constants';
import { useCheckRiskProfile } from '../../../../hooks/useCheckRiskProfile';
import { onPreventRedirect, redirectToReadOnlyPositionDetails } from '../../../common/utils';
import { useRejectProposal } from './hooks/useRejectProposal';
import { useModifyAndEditAllocationData } from '../../../../hooks/useModifyAndEditAllocationData';
import './ProposalDetails.css';

function ProposalDetails(props) {
    const {
        contactId: clientId,
        match: { params: { proposalId, portfolioId } },
    } = props;
    const { t } = useTranslation();
    const { getFormattedNumber, getFormattedCurrency } = useFormatting();
    const [showPendingOrdersError, setPendingOrdersError] = useState(false);

    // Portfolio Domain
    const {
        data, dataRaw, isLoading, error,
    } = usePortfolioSelector(portfolioSelector);
    const {
        data: proposal, isLoading: isLoadingProposal, error: errorProposal,
        getProposalDetails, dataRaw: dataRawProposal,
    } = usePortfolioSelector(proposalSelector);
    const adaptOptions = useMemo(() => ({
        currency: dataRaw?.Currency,
        portfolioValue: data?.portfolioValue,
        securitiesValue: data?.portfolioValue,
    }), [dataRaw.Currency, data?.securitiesValue, data?.portfolioValue]);

    const allocations = useMemo(() => dataRawProposal?.Positions?.map(
        (position) => ({
            Allocation: parseFloat(position.Allocation.toFixed(4)),
            InstrumentId: position.Security?.Id,
        }),
    ), [dataRawProposal]);

    const {
        alocationsData,
        isAllocationLoading,
        allocationError,
    } = useModifyAndEditAllocationData({
        clientId,
        currency: dataRaw?.Currency?.CurrencyCode,
        investmentAllocations: allocations,
        isLoading,
        portfolioId,
    });

    useEffect(() => {
        if (adaptOptions?.currency) {
            getProposalDetails(proposalId, { adaptOptions });
        }
    }, [getProposalDetails, adaptOptions, proposalId]);

    // Data
    const baseUrl = `/portfolios/${portfolioId}/proposal/${proposalId}/position/${data?.productId}`;
    const positions = useMemo(() => groupComparedPositions({
        positions: dataRaw?.Positions,
        modelPositions: dataRawProposal?.Positions || dataRaw?.Positions,
        portfolioCurrency: data?.currency,
        baseUrl,
        t,
        portfolioTotalValue: data?.investedAmount,
        redirectToReadOnlyPositionDetails,
        getFormattedNumber,
        getFormattedCurrency,
    }), [
        dataRaw.Positions, dataRawProposal.Positions, data.currency, data.investedAmount,
        baseUrl, t, getFormattedNumber, getFormattedCurrency,
    ]);

    const { data: { ChosenRiskCategory } } = useClientProfile();

    // Hooks
    const {
        benchmark, benchmarkOptions, benchmarkSelected, onBenchmarkChange, isLoadingBenchmark,
    } = useBenchmarks(data, isLoading, true);
    const {
        analysisData, isLoadingModel, errorModel,
        onPerformanceChange, performanceSelected, volatility,
    } = useAnalysisDetails({
        clientId,
        portfolio: data,
        strategy: proposal,
        positions,
        isLoading: isLoading || isLoadingProposal,
        benchmark,
        actionType: PROPOSAL,
        additionalOptions: {
            usePortfolioAllocations: true,
        },
    });
    const {
        onReject, isRejecting, errorRejecting,
    } = useRejectProposal(clientId, portfolioId, proposalId);

    // Risk Profile Check
    const [isRiskHigher, setRiskHigher] = useState(undefined);
    const {
        checkRiskProfile,
        compareVolatility,
        clientRiskBandwidth,
    } = useCheckRiskProfile(clientId, data.productId);
    const { riskCategoryId, riskCategoryName } = useMemo(() => ({
        riskCategoryId: dataRaw?.RiskCategory?.Id,
        riskCategoryName: dataRaw?.RiskCategory?.Name,
    }), [dataRaw?.RiskCategory]);

    useEffect(() => {
        if (proposal?.riskCategoryId && proposal.modelPortfolioId) {
            setRiskHigher(
                !checkRiskProfile(proposal.riskCategoryId, riskCategoryId),
            );
        }
    }, [proposal.riskCategoryId, proposal.modelPortfolioId, checkRiskProfile, riskCategoryId]);

    useEffect(() => {
        if (volatility && !proposal.modelPortfolioId) {
            setRiskHigher(undefined);
            compareVolatility(volatility, riskCategoryId).then((passedRisk) => {
                setRiskHigher(!passedRisk);
            });
        }
    }, [volatility, proposal.modelPortfolioId, compareVolatility, riskCategoryId]);

    // Renderers
    const AllocationRender = useMemo(() => (
        <AllocationCompareWithITL
            data={alocationsData}
            isLoading={isLoading || isAllocationLoading}
            error={error || allocationError}
        />
    ), [alocationsData, isAllocationLoading, isLoading, isLoadingProposal]);
    const renderRiskDisclaimer = () => (
        proposal.modelPortfolioId
            ? <RiskDisclaimer PRP={proposal?.strategy} CRP={riskCategoryName} />
            : (
                <RiskBandwidthDisclaimer
                    risk={volatility}
                    min={clientRiskBandwidth?.Min}
                    max={clientRiskBandwidth?.Max}
                    CRP={riskCategoryName}
                />
            )
    );

    // Handlers/callbacks
    const onCancel = (url) => {
        Modal.confirm({
            title: t('proposal.backToPortfolio'),
            content: t('proposal.backToPortfolioContent'),
            okText: t('proposal.backToPortfolio'),
            onOk: () => {
                history.push(url);
            },
            cancelText: t('proposal.cancel'),
            className: 'discard-changes',
        });
    };
    const onRejectProposal = () => {
        Modal.confirm({
            title: t('proposal.rejectProposal'),
            content: t('proposal.rejectProposalContent'),
            okText: t('proposal.reject'),
            onOk: () => {
                onReject(`/portfolios/${portfolioId}/proposal/${proposalId}/reject-success`, {
                    title: proposal.title || NA,
                });
            },
            cancelText: t('proposal.cancel'),
            className: 'discard-changes',
        });
    };
    const onContinue = () => {
        if (data.hasPendingOrders) {
            setPendingOrdersError(true);

            return;
        }
        if (isRiskHigher) {
            Modal.confirm({
                title: t('overview.disclaimer'),
                content: t('riskProfile.riskProfileProposalContent'),
                okText: t('confirmation.yes'),
                cancelText: t('confirmation.no'),
                onOk: () => {
                    history.push(`/portfolios/${portfolioId}/proposal/${proposalId}/orders`);
                },
                className: 'accept-risk-profile',
            });
        } else {
            history.push(`/portfolios/${portfolioId}/proposal/${proposalId}/orders`);
        }
    };

    return (
        <Preloader
            isLoading={isLoading || isLoadingProposal}
            error={error || errorProposal}
        >
            <ContentBox className="proposal-details with-mb" underline={false}>
                <BreadCrumbs current={t('proposal.reviewProposal')}>
                    <Link
                        to="/portfolios"
                        onClick={(e) => onPreventRedirect(e, '/portfolios', onCancel)}
                    >
                        {t('portfolios.title')}
                    </Link>
                    <Link
                        to={`/portfolios/${portfolioId}`}
                        onClick={(e) => onPreventRedirect(e, `/portfolios/${portfolioId}`, onCancel)}
                    >
                        {data.title}
                    </Link>
                </BreadCrumbs>
                <Title className="mb-0" type={1}>
                    {proposal.title || NA}
                </Title>
                <p className="mb-0">
                    {t('proposal.created')}
                    &nbsp;
                    {proposal?.createdDate}
                </p>
                {isRiskHigher && !isLoading && renderRiskDisclaimer()}
                <AccordionWrapper className="confirmation">
                    <Accordion defaultActiveKey={['1', '2', '3', '4', '5']}>
                        <Panel header={t('proposal.overview')} key="1">
                            <Overview
                                data={{ ...data.overview, newStrategy: proposal?.strategy }}
                                isLoading={isLoading}
                                error={error}
                                riskName={ChosenRiskCategory}
                            />
                        </Panel>
                        <Panel header={t('confirmation.allocation')} key="2" className="allocation">
                            {AllocationRender}
                        </Panel>
                        <Panel header={t('confirmation.analysis')} key="3" className="analysis">
                            <Analysis
                                data={analysisData}
                                onFilterChange={onPerformanceChange}
                                benchmarkOptions={benchmarkOptions}
                                benchmarkSelected={benchmarkSelected}
                                onBenchmarkChange={onBenchmarkChange}
                                isLoadingBenchmarks={isLoadingBenchmark}
                                performanceSelected={performanceSelected}
                                isLoading={isLoadingModel}
                                error={errorModel}
                            />
                        </Panel>
                        <Panel header={t('confirmation.positions')} className="positions" key="4">
                            <Positions
                                data={positions}
                                isLoading={isLoading}
                                error={error}
                                defaultExpandAllRows
                            />
                        </Panel>
                    </Accordion>
                </AccordionWrapper>
                {errorRejecting && <Infobox error>{errorRejecting.message}</Infobox>}
                <ButtonsBlockRow
                    leftButton={{
                        text: t('confirmation.back'),
                        onClick: () => onCancel(`/portfolios/${portfolioId}`),
                    }}
                    additionalButton={{
                        type: 'danger',
                        text: t('proposal.reject'),
                        loading: isRejecting,
                        disabled: isRiskHigher === undefined,
                        onClick: onRejectProposal,
                    }}
                    primaryButton={{
                        text: t('confirmation.continue'),
                        loading: isRejecting,
                        disabled: isRiskHigher === undefined,
                        onClick: onContinue,
                    }}
                >
                    {showPendingOrdersError && <Infobox error>{t('proposal.pendingOrdersError')}</Infobox>}
                </ButtonsBlockRow>
            </ContentBox>
        </Preloader>
    );
}

ProposalDetails.propTypes = {
    match: PropTypes.shape({
        params: {
            proposalId: PropTypes.string.isRequired,
        },
        path: PropTypes.string,
    }).isRequired,
    contactId: PropTypes.number.isRequired,
};

ProposalDetails.defaultProps = {};

const mapStateToProps = (state) => ({
    contactId: contactIdSelector(state),
});

export default connect(mapStateToProps)(ProposalDetails);
