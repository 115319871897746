import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
    TabPane, Tabs, Title, ContentBox, TabsExtraItem,
} from 'ui-library';
import { useTranslation } from 'react-i18next';
import {
    PIE, BAR, LIST, INV_ALLOC_DATA_KEYS,
} from 'constants/constants';
import { useFormatting } from 'locale';
import { DEFAULT_TOGGLE_STATE, SHOW_TOGGLE } from 'constants/flt';
import { allocationFormat } from 'utils/formatting';
import ChartType from '../ChartType';
import PageSection from '../PageSection/PageSection';
import Preloader from '../Preloader';
import EmptyContent from '../EmptyContent';
import { AllocationToogle } from '../AllocationToogle/AllocationToogle';

import './InvestmentAllocationWithILT.css';

function InvestmentAllocationWithILT({ data: investmentData, isLoading, error }) {
    const { t } = useTranslation();
    const [withLookThroughtData, setWithLookThroughtData] = useState(DEFAULT_TOGGLE_STATE);

    const data = useMemo(
        () => investmentData[
            withLookThroughtData
                ? INV_ALLOC_DATA_KEYS.withLookThroughtData
                : INV_ALLOC_DATA_KEYS.withoutLookThroughtData
        ],
        [withLookThroughtData, investmentData],
    );
    const toogleHandler = () => {
        setWithLookThroughtData((prev) => !prev);
    };
    const { getFormattedCurrency } = useFormatting();
    const [chartView, setChartView] = useState(PIE);
    const changeView = (type) => () => setChartView(type);
    const numberFormat = (number) => getFormattedCurrency(number, {
        currency: data.currency,
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    });
    const labelFormat = (label, value) => `<label class="Form-control-label"><span>${label}</span></label>${numberFormat(value?.y || value)}`;
    const tabBarExtraContent = () => (
        <>
            <TabsExtraItem
                icon="chart-pie"
                isActive={chartView === PIE}
                onClick={changeView(PIE)}
            />
            <TabsExtraItem
                icon="chart-bar-horizontal"
                isActive={chartView === BAR}
                onClick={changeView(BAR)}
            />
            <TabsExtraItem icon="list" isActive={chartView === LIST} onClick={changeView(LIST)} />
        </>
    );

    return (
        <PageSection className="investment-allocation">
            <ContentBox underline={false}>
                <Title type={2}>{t('dashboard.investmentAllocation.title')}</Title>
                <Preloader isLoading={isLoading} error={error}>
                    <EmptyContent data={data.chart} text={t('dashboard.noData')}>
                        <Tabs defaultActiveKey="1" tabBarExtraContent={tabBarExtraContent()}>
                            {data.chart.map((item, i) => (
                                <TabPane
                                    tab={t(item.title)}
                                    className={`TabPane-${chartView}`}
                                    key={(i + 1).toString()}
                                >
                                    <EmptyContent
                                        data={item.data[chartView].data}
                                        text={t('dashboard.noData')}
                                    >
                                        <ChartType
                                            data={item.data[chartView].data}
                                            title={item.title || item.name}
                                            labels={item.data[chartView].labels}
                                            currency={data.currency}
                                            chartView={chartView}
                                            labelFormat={labelFormat}
                                            numberFormat={numberFormat}
                                            percentFormat={allocationFormat}
                                            barHeight={60}
                                            t={t}
                                        />

                                    </EmptyContent>
                                </TabPane>
                            ))}
                        </Tabs>

                    </EmptyContent>
                    { SHOW_TOGGLE && (
                        <AllocationToogle
                            value={withLookThroughtData}
                            onChange={toogleHandler}
                        />
                    )}
                </Preloader>

            </ContentBox>
        </PageSection>
    );
}

InvestmentAllocationWithILT.propTypes = {
    data: PropTypes.shape({
        chart: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                data: PropTypes.shape({
                    [PropTypes.string]: PropTypes.arrayOf(
                        PropTypes.shape({
                            name: PropTypes.string,
                            value: PropTypes.number,
                        }),
                    ),
                }),
            }),
        ),
        currency: PropTypes.string,
    }),
    isLoading: PropTypes.bool,
    error: PropTypes.oneOf([PropTypes.bool, PropTypes.string]),
};

InvestmentAllocationWithILT.defaultProps = {
    data: { chart: [] },
    isLoading: true,
    error: null,
};

export default InvestmentAllocationWithILT;
