import { INV_ALLOC_DATA_KEYS, ALLOCATION_TYPES } from 'constants/constants';


export const DEFAULT_CHART_VALUE = {
    chart: [{
        data: {
            pieSa: {}, bar: {}, list: {}, pie: {},
        },
    }],
};

export const DEFAULT_ALLOCATION_VALUE = {
    [INV_ALLOC_DATA_KEYS.withLookThroughtData]: {
        [ALLOCATION_TYPES.CURRENT_ALLOCATION]: DEFAULT_CHART_VALUE,
        [ALLOCATION_TYPES.NEW_ALLOCATION]: DEFAULT_CHART_VALUE,
    },
    [INV_ALLOC_DATA_KEYS.withoutLookThroughtData]: {
        [ALLOCATION_TYPES.CURRENT_ALLOCATION]: DEFAULT_CHART_VALUE,
        [ALLOCATION_TYPES.NEW_ALLOCATION]: DEFAULT_CHART_VALUE,
    },
};
export { INV_ALLOC_DATA_KEYS, ALLOCATION_TYPES };
