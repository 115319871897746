import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Toggle } from 'ui-library';
import './AllocationToogle.css';

function AllocationToogleComponent({ value, onChange }) {
    return (
        <div className="allocation-toogle-container">
            <Toggle value={value} onChange={onChange} />
            <p className="title">Fund lookthrough</p>
        </div>
    );
}

AllocationToogleComponent.propTypes = {
    value: PropTypes.bool,
    onChange: PropTypes.func,
};

AllocationToogleComponent.defaultProps = {
    value: false,
    onChange: () => {},
};
export const AllocationToogle = memo(AllocationToogleComponent);
