import { renderers } from 'ui-library';
import {
    CellLink, CellPercent, CellWithMoreActions, CellAdaptedDate, CellStatusBadge,
} from 'components/renderers';
import {
    sorterString, sorterByDate, sorterNumber, sorterStatusColor,
} from 'utils/sorting';
import history from 'services/history';
import CellPDFView from 'components/renderers/CellPDFView';
import { EXECUTION_ONLY, DISCRETIONARY } from '../pages/pages/Portfolios/pages/common/constants';
import { getDateFormat, getDateTimeFormat } from '../utils/datetime';

const {
    CellWithIcon,
    CellWithSubtext,
    CellWithLinkIcon,
    CellWithHyperlinkIcon,
    CellWithButtonIcon,
} = renderers;

export const LOGGED_IN = 'LOGGED_IN';

export const CURRENT_PAGE = 1;
export const PAGE_SIZE = 8;
export const TOTAL_PAGES = 10;
export const ALL_PAGES = 1000;

export const SORT_DIRECTION_ASC = 'asc';
export const SORT_DIRECTION_DESC = 'desc';

export const CHANGE_STRATEGY = 'change-strategy';
export const CHANGE_MODEL = 'change-model';
export const MODIFY = 'modify';
export const QUICK_EDIT = 'quick-edit';
export const REBALANCE = 'rebalance';
export const PROPOSAL = 'proposal';
export const MODIFY_PORTFOLIO = 'modify-portfolio';

export const PIE = 'pie';
export const PIE_SA = 'pieSa';
export const BAR = 'bar';
export const LIST = 'list';
export const LINE = 'line';
export const SUSTAINABILITY = 'sustainability';
export const RISKRETURN = 'riskReturn';
export const BANDWIDTH = 'bandwidth';
export const PROJECTION = 'projection';

export const RISKRETURN_MOBILE_HEIGHT = 300;
export const RISKRETURN_HEIGHT = 400;

export const MOBILE_VIEWPORT_MIN_WIDTH = 540;
export const TABLET_VIEWPORT_MIN_WIDTH = 768;

export const PAYMENTS = 'payments';
export const PAYMENT = 'payment';

export const allocationColumns = (t) => [
    {
        key: 'name',
        title: t('Allocation.columns.name'),
        sortable: true,
        width: 150,
        sorter: sorterString('name'),
        defaultSortOrder: 'descend',
    },
    {
        key: 'value',
        title: t('Allocation.columns.value'),
        width: 100,
        sortable: true,
        className: 'text-left',
        sorter: sorterNumber('value'),
    },
    {
        key: 'percent',
        title: t('Allocation.columns.allocation'),
        width: 100,
        sortable: true,
        className: 'text-right',
        sorter: sorterNumber('percent'),
    },
];

export const profileColumns = [
    {
        key: 'Information',
        render: CellWithSubtext,
    },
    {
        key: 'Icon',
        className: 'text-right',
        render: CellWithLinkIcon,
    },
];

export const messagesColumns = (t) => [
    {
        key: 'Subject',
        title: t('messages.subject'),
        sortable: true,
        sorter: sorterString('Subject.title'),
        render: CellWithSubtext,
        width: 215,
        className: 'table-cell__subject',
    },
    {
        key: 'Date',
        title: t('messages.date'),
        sortable: true,
        sorter: sorterByDate('Date'),
        width: 103,
        className: 'text-right table-cell__date',
        render: CellAdaptedDate(t),
    },
];

export const LIQUIDITY_TYPE = 'Liquidity';

export const TRANSACTION_TYPE = {
    1: 'transactions.credit',
    2: 'transactions.debit',
};

export const messagesSorting = { key: 'Date', direction: 'asc' };

export const portfoliosColumns = (t, getFormattedNumber) => [
    {
        key: 'Name',
        title: t('portfolios.columns.name'),
        sortable: true,
        sorter: sorterString('Name.label'),
        render: CellLink,
    },
    {
        key: 'Product',
        title: t('portfolios.columns.product'),
        sorter: sorterString('Product'),
    },
    {
        key: 'Profile',
        title: t('portfolios.columns.profile'),
        sorter: sorterString('Profile'),
    },
    {
        key: 'Status',
        title: t('portfolios.columns.status'),
        sorter: sorterStatusColor('Status'),
        sortable: true,
        render: CellStatusBadge,
    },
    {
        key: 'ExternalId',
        title: t('portfolios.columns.externalId'),
        hidden: true,
        sorter: sorterString('ExternalId'),
    },
    {
        key: 'Value',
        title: t('portfolios.columns.value'),
        className: 'text-right',
        sortable: true,
        sorter: sorterNumber('Value'),
    },
    {
        key: 'Performance',
        title: t('portfolios.columns.performanceSI'),
        className: 'text-right',
        width: 160,
        sortable: true,
        render: CellPercent(getFormattedNumber),
        sorter: sorterNumber('Performance'),
    },
    {
        title: '',
        className: 'text-right table-cell__actions',
        key: 'Actions',
        render: CellWithMoreActions,
        width: 30,
        minWidth: 30,
        titleType: 'columns',
        fixed: 'right',
        headerRenderer: CellWithIcon,
    },
];

export const portfoliosMobileColumns = (t, getFormattedNumber) => [
    {
        key: 'Name',
        title: t('portfolios.columns.name'),
        sortable: true,
        sorter: sorterString('Name.label'),
        render: CellLink,
    },
    {
        key: 'Status',
        title: t('portfolios.columns.status'),
        sorter: sorterString('Status'),
        sortable: true,
        render: CellStatusBadge,
    },
    {
        key: 'Value',
        title: t('portfolios.columns.value'),
        className: 'text-right',
        sortable: true,
        sorter: sorterNumber('Value'),
    },
    {
        key: 'Performance',
        title: t('portfolios.columns.performanceSI'),
        className: 'text-right',
        width: 160,
        sortable: true,
        render: CellPercent(getFormattedNumber),
        sorter: sorterNumber('Performance'),
        hidden: true,
    },
    {
        title: '',
        className: 'text-right table-cell__actions',
        key: 'Actions',
        render: CellWithMoreActions,
        width: 30,
        minWidth: 30,
        titleType: 'columns',
        fixed: 'right',
        headerRenderer: CellWithIcon,
    },
];

export const portfolioActions = ({
    id, productName, t, withEditActions = true, withPendingOrders, withFundingInformation,
}) => {
    if (withPendingOrders) {
        return [
            {
                text: `${t('portfolios.pendingOrder')}`,
                onClick: () => history.push(`/portfolios/${id}/pending-orders`),
            },
        ];
    }

    if (withFundingInformation) {
        return [
            {
                text: `${t('fundingInformation.title')}`,
                onClick: () => history.push(`/portfolios/${id}/funding-information`),
            },
        ];
    }

    const actions = [
        {
            text: `${t('portfolios.openPortfolio')}`,
            onClick: () => history.push(`/portfolios/${id}`),
        },
    ];

    if (withEditActions) {
        const rebalancePortfolioLinks = [
            {
                text: `${t('portfolios.changeStrategy')}`,
                onClick: () => history.push(`/portfolios/${id}/actions/change-strategy`),
            },
            {
                text: `${t('overview.changeModel')}`,
                onClick: () => history.push(`/portfolios/${id}/actions/change-model`),
            },
            {
                text: `${t('portfolios.rebalance')}`,
                onClick: () => history.push(`/portfolios/${id}/actions/rebalance`),
            },
        ];
        const editPortfolioLinks = [
            {
                text: `${t('portfolios.quickEdit')}`,
                onClick: () => history.push(`/portfolios/${id}/actions/quick-edit`),

            },
            {
                text: `${t('portfolios.modify')}`,
                onClick: () => history.push(`/portfolios/${id}/actions/modify`),

            },
        ];

        if (productName === DISCRETIONARY) {
            actions.push(...rebalancePortfolioLinks.slice(0, 2));

            return actions;
        }

        if (productName === EXECUTION_ONLY) {
            return actions.concat(editPortfolioLinks);
        }

        return actions.concat(rebalancePortfolioLinks).concat(editPortfolioLinks);
    }

    return actions;
};

export const lineChartLabels = (t) => ({
    months: t('months').split('_'),
    shortMonths: t('shortMonths').split('_'),
});

// TODO: unused code below, delete after approval
export const relationshipManagerColumns = [
    {
        key: 'Information',
        render: CellWithSubtext,
        width: 'calc(100% - 40px)',
    },
    {
        key: 'Icon',
        width: 40,
        className: 'text-right',
        render: CellWithHyperlinkIcon,
    },
];

export const documentsColumns = (t) => [
    {
        key: 'Icon',
        render: CellWithButtonIcon,
        width: 20,
    },
    {
        key: 'Document',
        sortable: true,
        sorter: sorterString('Name.value'),
        width: 185,
        render: CellPDFView,
    },
    {
        key: 'Date',
        sortable: true,
        sorter: sorterByDate('Date'),
        width: 103,
        className: 'text-right table-cell__date',
        render: CellAdaptedDate(t),
    },
];

export const documentsSorting = { key: 'Date', direction: 'asc' };

export const invPrfLineChartValueFormat = {
    symbol: '%',
    precision: 0,
    thousand: ',',
    decimal: '.',
    format: '%v%s',
};

export const invPrfSelectOptions = ['YTD', 'Last 12 months', '2 Years', '5 Years', 'Max'];

export const overviewSelectOptions = ['Month', 'Year'];

export const datePickerLocale = (t, locale) => ({
    lang: {
        locale,
        placeholder: 'Select date',
        rangePlaceholder: ['Start date', 'End date'],
        today: t('calendar.today'),
        now: t('calendar.now'),
        backToToday: t('calendar.backToToday'),
        ok: t('calendar.ok'),
        cancel: t('calendar.cancel'),
        clear: t('calendar.clear'),
        month: t('calendar.month'),
        year: t('calendar.year'),
        timeSelect: t('calendar.selectTime'),
        dateSelect: t('calendar.selectDate'),
        monthSelect: t('calendar.chooseMonth'),
        yearSelect: t('calendar.chooseYear'),
        decadeSelect: t('calendar.chooseDecade'),
        yearFormat: 'YYYY',
        dateFormat: getDateFormat(locale),
        dayFormat: 'D',
        dateTimeFormat: getDateTimeFormat(locale),
        monthFormat: 'MMMM',
        monthBeforeYear: true,
        previousMonth: t('calendar.prevMonth'),
        // 'Previous month (PageUp)',
        nextMonth: t('calendar.nextMonth'),
        // 'Next month (PageDown)',
        previousYear: t('calendar.lastYear'),
        // 'Last year (Control + left)',
        nextYear: t('calendar.nextYear'),
        // 'Next year (Control + right)',
        previousDecade: t('calendar.lastDecade'),
        // 'Last decade',
        nextDecade: t('calendar.nextDecade'),
        // 'Next decade',
        previousCentury: t('calendar.lastCentury'),
        // 'Last century',
        nextCentury: t('calendar.nextCentury'),
        // 'Next century',
    },
    timePickerLocale: {
        placeholder: t('calendar.selectTime'),
        // 'Select time',
    },
    dateFormat: getDateFormat(locale),
    dateTimeFormat: getDateTimeFormat(locale),
    weekFormat: 'YYYY-wo',
    monthFormat: 'MM-YYYY',
});

export const IBAN = 'IBAN';
export const INV_ALLOC_DATA_KEYS = {
    withLookThroughtData: 'withLookThroughtData',
    withoutLookThroughtData: 'withoutLookThroughtData',
};
export const ALLOCATION_TYPES = {
    NEW_ALLOCATION: 'NEW_ALLOCATION',
    CURRENT_ALLOCATION: 'CURRENT_ALLOCATION',
};
