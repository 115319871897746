import { useCallback, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useApolloClient, gql } from '@apollo/client';
import SM from 'services/ServiceManager';
import ServerError from 'errors/ServerError';
import AdapterError from 'errors/AdapterError';
import HandlerError from 'errors/HandlerError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import { getFirstDayOfYear } from 'utils/datetime';
import { useFormatting } from 'locale';
import { adaptSecuritiesHistory } from 'adaptors/adaptSecuritiesHistory';
import { adaptResearch } from '../adapters/adaptResearch';
import { adaptResearches } from '../adapters/adaptResearches';

const GET_SECURITY = gql`
    query GetSecurity($positionId: Int!) {
        securities(
            where: { id: { eq: $positionId } }
        ){
            nodes {
                name
                logoUrl
                tradingPrice
                currency { id isoCode }
            }
        }
    }
`;

const initialState = {
    list: [],
    listRaw: [],
    errorList: null,
    isLoadingList: false,
    data: { instrument: {}, keyTakeaways: [] },
    dataRaw: {},
    error: null,
    isLoading: false,
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'setList':
            return { ...state, list: action.payload };
        case 'setListRaw':
            return { ...state, listRaw: action.payload };
        case 'setErrorList':
            return { ...state, errorList: action.payload };
        case 'setIsLoadingList':
            return { ...state, isLoadingList: action.payload };
        case 'setData':
            return { ...state, data: action.payload };
        case 'setDataRaw':
            return { ...state, dataRaw: action.payload };
        case 'setError':
            return { ...state, error: action.payload };
        case 'setIsLoading':
            return { ...state, isLoading: action.payload };
        default:
            return state;
    }
};

export const useResearch = (options = {}) => {
    const {
        researchId,
        adaptData = adaptResearch,
        adaptList = adaptResearches,
        loadInitially = false,
        loadListInitially = false,
        listSize,
        pageNumber,
    } = options;
    const [state, dispatch] = useReducer(reducer, initialState);

    const client = useApolloClient();
    const { t, i18n: { language } } = useTranslation();
    const { getFormattedNumber, getFormattedDate } = useFormatting();

    // Callbacks
    const getResearches = useCallback(async ({ pageSize, page, skipLoading }) => {
        dispatch({ type: 'setErrorList', payload: null });
        if (!skipLoading) dispatch({ type: 'setIsLoadingList', payload: true });

        try {
            const { data: schemas } = await SM.engagementSchema('postAll', [{ pageNumber: 0, pageSize: 10 }]);
            const researchSchemaId = (schemas?.schemaMinimalResults || []).find(({ name }) => name === 'Research')?.id;

            if (!researchSchemaId) {
                dispatch({ type: 'setIsLoadingList', payload: false });

                return [];
            }

            const { data: contents } = await SM.engagementContent('postSchemaLatest', [{ schemaId: researchSchemaId, pageNumber: 0, pageSize: 0 }]);
            const ids = (contents?.contentMinimalResults || []).map((item) => item?.id);
            const contentFor = (contents?.contentMinimalResults || [])
                .map((item) => item?.contentFor?.Security).flat()
                .filter((item) => !!item);
            let imagesResponses = [];

            try {
                imagesResponses = await Promise.allSettled(ids.map(async (id) => ({
                    id,
                    ...(await SM.engagementContent('getContentLatestImage', [id, language])).data,
                })));
            } catch (err) {
                // No need to display error
            }
            const images = imagesResponses.reduce((acc, item) => (item?.value
                ? { ...acc, [item.value?.id]: item.value?.content } : acc), {});
            const response = await SM.engagementContent('contentSearch', [{
                contentFor,
                categories: ['Security'],
                status: true,
                isPublished: true,
                page: page || 1,
                pageSize: pageSize || contentFor.length,
            }, language]);

            try {
                const adapted = adaptList({
                    data: (response.data?.contentMinimalResults || []).map((item) => ({
                        ...item, imageUri: images[item.id],
                    })),
                    schema: contents?.contentMinimalResults,
                    t,
                    language,
                });

                dispatch({ type: 'setList', payload: adapted });
                dispatch({ type: 'setListRaw', payload: response.data });
                if (!skipLoading) dispatch({ type: 'setIsLoadingList', payload: false });

                return adapted;
            } catch (err) {
                throw new AdapterError(err);
            }
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setErrorList', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setIsLoadingList', payload: val }),
                }),
            )(err);

            return err.type !== undefined ? err : new ServerError(err);
        }
    }, [adaptList, t]);
    const getResearch = useCallback(async (id = researchId) => {
        dispatch({ type: 'setError', payload: null });
        dispatch({ type: 'setIsLoading', payload: true });

        try {
            const { data } = await SM.engagementContent('getContentLatest', [id]);
            let dataImage = null;
            let dataDocument = null;

            try {
                dataImage = (await SM.engagementContent('getContentLatestImage', [id, language])).data;
                dataDocument = (await SM.engagementContent('getContentLatestDocument', [id, language])).data;
            } catch (err) {
                // No need to display error
            }

            const itemData = JSON.parse(data?.data || '{}');
            const submissionData = itemData?.submissions?.[0]?.submission?.data
                || itemData?.submission?.data;
            const securityId = submissionData?.securityId;
            let security = null;
            let responsePrice = null;

            if (securityId) {
                const { data: response } = await client.query({
                    query: GET_SECURITY,
                    variables: { positionId: securityId },
                });

                security = response?.securities?.nodes?.[0];

                const params = {
                    FromDate: getFirstDayOfYear(),
                    ToDate: new Date(),
                };

                try {
                    responsePrice = await Promise.all([
                        SM.instrumentsService('postPriceHistory', [securityId, params]),
                        SM.instrumentsService('postPerformanceHistory', [securityId, params]),
                    ]);
                } catch (err) {
                    // No need to display error
                }
            }

            try {
                const adapted = adaptData({
                    data: {
                        ...data,
                        imageUri: dataImage?.content,
                    },
                    dataDocument,
                    security,
                    securityPrice: adaptSecuritiesHistory(
                        responsePrice[0]?.data?.historicalData,
                    ),
                    securityPerformance: adaptSecuritiesHistory(
                        responsePrice[1]?.data?.historicalData,
                    ),
                    t,
                    getFormattedNumber,
                    getFormattedDate,
                    language,
                });

                dispatch({ type: 'setData', payload: adapted });
                dispatch({ type: 'setDataRaw', payload: data });
                dispatch({ type: 'setIsLoading', payload: false });

                return data;
            } catch (err) {
                throw new AdapterError(err);
            }
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setError', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setIsLoading', payload: val }),
                }),
            )(err);

            return err.type !== undefined ? err : new ServerError(err);
        }
    }, [adaptData, getFormattedNumber, language, researchId, t]);

    // Effects
    useEffect(() => {
        dispatch({ type: 'setIsLoadingList', payload: loadListInitially });
    }, [loadListInitially]);
    useEffect(() => {
        if (loadListInitially) getResearches({ pageSize: listSize, page: pageNumber });
    }, [getResearches, loadListInitially, pageNumber, listSize]);

    useEffect(() => {
        dispatch({ type: 'setIsLoading', payload: loadInitially });
    }, [loadInitially]);
    useEffect(() => {
        if (loadInitially) getResearch();
    }, [getResearch, loadInitially]);

    return {
        getResearch,
        list: state.list,
        listRaw: state.listRaw,
        errorList: state.errorList,
        isLoadingList: state.isLoadingList,
        getResearches,
        data: state.data,
        dataRaw: state.dataRaw,
        error: state.error,
        isLoading: state.isLoading,
    };
};
