import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import { useFormatting } from 'locale';
import ServerError from 'errors/ServerError';
import AdapterError from 'errors/AdapterError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import { portfolioActions } from 'constants/constants';
import { APIVersion } from 'additiv-services';
import { getDocumentsPortfolios } from '../utils';
import { adaptPortfolios } from '../../Portfolios/adapters/adaptPortfolios';


export const usePortfolios = ({ clientId, proposals }) => {
    const [error, setError] = useState(null);
    const [isLoadingPortfolios, setLoadingPortfolios] = useState(true);
    const { i18n, t } = useTranslation();
    const { getFormattedCurrency } = useFormatting();

    const getPortfolios = useCallback(async ({ documents }) => {
        setError(null);

        try {
            const params = { language: i18n.language };
            const { data: dataInvestment } = await ServiceManager.portfolioManagement('getInvestmentOverview', [clientId, params, APIVersion.V11]);

            const { data: allPortfolios } = await ServiceManager.portfolioManagement('getAllPortfolios', [clientId, params]);
            const {
                data: historicalPerfomance,
            } = await ServiceManager.portfolioManagement('getHistoricalPerformance', [clientId]);

            const investmentDataFromEndpoint = {
                ...dataInvestment,
                Portfolios: allPortfolios,
                HistoricalPerformance: [
                    {
                        SeriesType: 'Portfolio',
                        Values: historicalPerfomance.Values,
                    },
                ],
            };


            const filteredPortfolios = getDocumentsPortfolios({
                portfolios: allPortfolios, documents,
            });
            const portfoliosOverview = await Promise.all(filteredPortfolios.Portfolios
                .map(async ({ Id }) => ({
                    Id,
                    ...(await ServiceManager.portfolioManagement('getPortfolioOverview', [Id, clientId, params])).data,
                })));

            try {
                setLoadingPortfolios(false);

                return adaptPortfolios(
                    investmentDataFromEndpoint, {
                        proposals,
                        portfolioActions,
                        t,
                        getFormattedCurrency,
                        portfoliosOverview,
                    },
                );
            } catch (err) {
                throw new AdapterError(err);
            }
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError,
                    setLoadingPortfolios,
                }),
            )(err);

            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, [clientId, i18n.language, t, getFormattedCurrency, proposals]);

    return {
        getPortfolios, isLoadingPortfolios, error,
    };
};
