import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FileSaver from 'file-saver';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import { useLanguageId } from 'hooks/useLanguageId';
import { getTimezoneOffset, formatDate } from 'utils/datetime';
import { useLocale } from 'locale';

export const useAdvisoryProtocol = (options) => {
    const {
        contactId,
        clientName = '',
        details,
        benchmarkSelected,
        questionnaireTypeId,
        BankBranchId,
    } = options;
    const { locale } = useLocale();
    const { t, i18n } = useTranslation();
    const { getLanguageId } = useLanguageId();
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(false);

    const generateAdvisoryProtocol = useCallback(() => {
        if (!benchmarkSelected || benchmarkSelected === '' || !Object.keys(details || {}).length) {
            return;
        }

        setLoading(true);
        setError(null);

        getLanguageId()
            .then((language) => {
                const params = {
                    locale,
                    timeZoneOffset: getTimezoneOffset(),
                    PortfolioId: details.PortfolioId,
                    PortfolioProposalId: details.PortfolioProposalId,
                    ModelPortfolioId: details.ModelPortfolioId,
                    ProductId: details.ProductId,
                    QuestionnaireTypeId: questionnaireTypeId,
                    SecurityId: benchmarkSelected,
                    BankBranchId,
                    LanguageId: language.Id,
                    Language: i18n.language,
                    CalculationRequest: {
                        InstrumentSet: {
                            CurrencyId: details.CurrencyId,
                            Allocations: details.Allocations,
                            AllocationType: 'Percentage',
                        },
                        CalculationInterval: 'Monthly',
                    },
                };

                ServiceManager.reportsService('reportAvisoryProtocol', [contactId, params])
                    .then((response) => {
                        try {
                            setData(response.data);
                            setLoading(false);
                        } catch (err) {
                            setLoading(false);
                            setError(err);
                            throw new AdapterError(err);
                        }
                    })
                    .catch(handlerRequestCanceling(
                        HandlerError({ setLoading, setError }),
                    ));
            })
            .catch(handlerRequestCanceling(
                HandlerError({ setLoading, setError }),
            ));
    }, [
        i18n.language,
        locale,
        details,
        contactId,
        questionnaireTypeId,
        benchmarkSelected,
        getLanguageId,
        BankBranchId,
    ]);
    const saveAdvisoryProtocol = useCallback(() => {
        if (data) {
            FileSaver.saveAs(data, `${t('onBoarding.advisoryProtocol')} - ${clientName} - ${formatDate(new Date())}.pdf`);
        }
    }, [clientName, data, t]);

    useEffect(() => {
        if (BankBranchId) {
            generateAdvisoryProtocol();
        }
    }, [generateAdvisoryProtocol, BankBranchId]);

    return {
        error,
        isLoading,
        generateAdvisoryProtocol,
        saveAdvisoryProtocol,
    };
};
