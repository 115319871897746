import React, {
    useState,
    useEffect,
    useCallback,
    useMemo,
    ComponentType,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import { RenewTokenResponseData } from 'additiv-services/dist/types/services/security';
import { useSetting } from 'hooks/useSetting';
import ServiceManager from '../services/ServiceManager';
import {
    contactIdSelector,
    isRenewAccessTokenProcessStartedSelector,
    jwtAccessTokenSelector,
} from '../redux/auth/authSelectors';
import {
    logout,
    renewAccessToken,
    renewAccessTokenProcessStarted,
} from '../redux/auth/authActions';
import ServerError from '../errors/ServerError';
import handlerRequestCanceling from '../utils/handlerRequestCanceling';
import { groups, keys } from '../static/globalSettings';

export const withContinuousRenewAccessToken = (
    WrappedComponent: ComponentType
) => {
    const ContinuousRenewAccessToken = () => {
        const contactId = useSelector(contactIdSelector);
        const [isInitDone, setInit] = useState<boolean>(false);
        const dispatch = useDispatch();
        const isRenewAccessTokenProcessStarted: boolean = useSelector(
            isRenewAccessTokenProcessStartedSelector
        );
        const jwtAccessToken = useSelector(jwtAccessTokenSelector);
        const [timeoutId, setTimoutId] = useState<NodeJS.Timeout>();
        const { Value: accessTokenExpirationPeriod } = useSetting({
            group: groups.AUTHENTICATION,
            key: keys.CONTACT_ACCESS_TOKEN_LIFETIME,
        });

        const renewPeriod: number | undefined = useMemo(() => {
            if (accessTokenExpirationPeriod === undefined) return undefined;

            return (+accessTokenExpirationPeriod - 60) * 1000;
        }, [accessTokenExpirationPeriod]);

        const startRenewAccessTokenProcess = useCallback(async () => {
            try {
                const { data }: AxiosResponse<RenewTokenResponseData> =
                    await ServiceManager.Security('renewToken', [
                        {
                            urlParams: { contactId },
                            payload: { AccessToken: jwtAccessToken },
                        },
                    ]).catch((reason: AxiosError) => {
                        const status = reason?.response?.status;

                        if (status === 404 || status === 500) {
                            dispatch(logout());
                        }
                    });

                dispatch(
                    renewAccessToken({
                        sessionId: data.Session.SessionId,
                        jwtAccessToken: data.Session.JwtAccessToken,
                    })
                );
            } catch (err: any) {
                handlerRequestCanceling(() => new ServerError(err))(err);

                throw err.type !== undefined ? err : new ServerError(err);
            }

            renewAccessTokenAfterTimeout();
        }, [dispatch, contactId, jwtAccessToken, renewPeriod]);

        const renewAccessTokenAfterTimeout = useCallback(() => {
            const tid = setTimeout(startRenewAccessTokenProcess, renewPeriod);

            setTimoutId(tid);
        }, [renewPeriod]);

        useEffect(() => {
            if (!contactId || !renewPeriod) return undefined;

            if (!isInitDone) {
                if (
                    isRenewAccessTokenProcessStarted &&
                    timeoutId === undefined
                ) {
                    startRenewAccessTokenProcess();
                } else {
                    renewAccessTokenAfterTimeout();
                    dispatch(renewAccessTokenProcessStarted());
                }
                setInit(true);
            }

            return function cleanup() {
                if (timeoutId !== undefined) clearTimeout(timeoutId);
            };
        }, [contactId, timeoutId, renewPeriod]);

        //@ts-ignore
        return <WrappedComponent />;
    };

    return ContinuousRenewAccessToken;
};
