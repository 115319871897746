import React, { useMemo } from 'react';
import { FooterMini } from 'ui-library';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from 'translations/component/LanguageSwitcher';
import './Footer.css';

const { AppConfig, BuildInfoConfig } = global;

export const Footer = () => {
    const { t } = useTranslation();
    const title = useMemo(() => t('footer.copyright', { year: new Date().getFullYear() }), [t]);
    const devVersion = useMemo(() => (process.env.NODE_ENV !== 'development' && BuildInfoConfig
        ? `${BuildInfoConfig.BUILD || ''}${BuildInfoConfig.COMMIT ? `.${BuildInfoConfig.COMMIT}` : ''}`
        : null), []);
    const version = useMemo(() => (process.env.NODE_ENV === 'development' ? '' : `
        ${AppConfig.RELEASE_VERSION || ''}${devVersion ? `.${devVersion}` : ''}
        ${AppConfig.RELEASE_VERSION_CTP ? ` ${t('footer.runningOnCtp')} ${AppConfig.RELEASE_VERSION_CTP}` : ''}
    `), [devVersion, t]);

    return (
        <FooterMini title={title}>
            <LanguageSwitcher />
            <span dir="ltr" className="ignore-in-visual-regression">
                <b>{t('footer.productName')}</b>
                {` ${version}`}
                <br />
                {title}
            </span>
        </FooterMini>
    );
};

Footer.displayName = 'Footer';
