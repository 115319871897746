import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import { useLocale } from 'locale';
import ServerError from 'errors/ServerError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import useFormatting from 'locale/useFormatting';
import { APIVersion } from 'additiv-services';
import { adaptReviewPortfolios } from '../adapters/adaptReviewPortfolios';

export const useReviewPortfolios = ({ clientId, riskName }) => {
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const { i18n, t } = useTranslation();
    const { locale } = useLocale();
    const { getFormattedCurrency } = useFormatting();

    const getPortfolios = useCallback(async () => {
        if (!riskName) return null;

        setError(null);

        try {
            const params = { language: i18n.language };
            const { data: dataInvestment } = await ServiceManager.portfolioManagement('getInvestmentOverview', [clientId, params, APIVersion.V11]);

            const { data: allPortfolios } = await ServiceManager.portfolioManagement('getAllPortfolios', [clientId, params]);
            const {
                data: historicalPerfomance,
            } = await ServiceManager.portfolioManagement('getHistoricalPerformance', [clientId]);

            const investmentDataFromEndpoint = {
                ...dataInvestment,
                Portfolios: allPortfolios,
                HistoricalPerformance: [
                    {
                        SeriesType: 'Portfolio',
                        Values: historicalPerfomance.Values,
                    },
                ],
            };

            setData(adaptReviewPortfolios({
                data: investmentDataFromEndpoint,
                riskName,
                getFormattedCurrency,
                t,
            }));
            setLoading(false);

            return data;
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError,
                    setLoading,
                }),
            )(err);

            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, [i18n.language, locale, clientId, riskName, t]);

    useEffect(() => {
        getPortfolios();
    }, [getPortfolios, locale]);

    return { data, isLoading, error };
};
