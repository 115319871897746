import React, { useState, useEffect } from 'react';
import { TabPane, Tabs, TabsExtraItem } from 'ui-library';
import { useTranslation } from 'react-i18next';
import Preloader from 'components/Preloader';
import EmptyContent from 'components/EmptyContent';
import PropTypes from 'prop-types';
import { allocationPercentFormat } from 'utils/formatting';
import {
    BAR, LIST, PIE_SA, PIE,
} from 'constants/constants';
import ChartType from 'components/ChartType';
import { useDetectedMobileDevice } from 'hooks/useDetectedMobileDevice';
import { useFormatting } from 'locale';

function Allocation({ data, isLoading, error }) {
    const { t } = useTranslation();
    const { isMobileSize } = useDetectedMobileDevice();
    const { getFormattedCurrency } = useFormatting();
    const chartPieType = isMobileSize ? PIE : PIE_SA;
    const [chartView, setChartView] = useState(chartPieType);

    const changeView = (type) => () => setChartView(type);
    const numberFormat = (number) => getFormattedCurrency(number, {
        currency: data.currency,
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    });
    const labelFormat = (label, value) => `<label class="Form-control-label"><span>${label}</span></label>${numberFormat(value?.y || value)}`;

    const percentFormat = isMobileSize ? undefined : allocationPercentFormat;

    useEffect(() => {
        if ([PIE, PIE_SA].includes(chartView)) {
            setChartView(chartPieType);
        }
    }, [isMobileSize]);
    const tabBarExtraContent = () => (
        <>
            <TabsExtraItem
                icon="chart-pie"
                isActive={chartView === chartPieType}
                onClick={changeView(chartPieType)}
            />
            <TabsExtraItem
                icon="chart-bar-horizontal"
                isActive={chartView === BAR}
                onClick={changeView(BAR)}
            />
            <TabsExtraItem icon="list" isActive={chartView === LIST} onClick={changeView(LIST)} />
        </>
    );

    return (
        <Preloader isLoading={isLoading} error={error}>
            <Tabs
                className="investment-allocation"
                defaultActiveKey="1"
                tabBarExtraContent={tabBarExtraContent()}
            >
                {data.chart.map((item, i) => (
                    <TabPane tab={t(item.title)} key={(i + 1).toString()}>
                        <EmptyContent data={item.data?.[chartView]?.data} text={t('dashboard.noData')}>
                            <ChartType
                                data={item.data?.[chartView]?.data}
                                title={item.title || item.name}
                                labels={item.data?.[chartView]?.labels}
                                currency={data.currency}
                                chartView={chartView}
                                labelFormat={labelFormat}
                                numberFormat={numberFormat}
                                percentFormat={percentFormat}
                                barHeight={60}
                                t={t}
                            />
                        </EmptyContent>
                    </TabPane>
                ))}
            </Tabs>
        </Preloader>
    );
}

Allocation.propTypes = {
    data: PropTypes.shape({
        chart: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                data: PropTypes.shape({
                    [PropTypes.string]: PropTypes.arrayOf(
                        PropTypes.shape({
                            name: PropTypes.string,
                            value: PropTypes.number,
                        }),
                    ),
                }),
            }),
        ),
        currency: PropTypes.string,
    }),
    isLoading: PropTypes.bool,
    error: PropTypes.oneOf([PropTypes.bool, PropTypes.string]),
};

Allocation.defaultProps = {
    data: { chart: [] },
    isLoading: true,
    error: null,
};

export default Allocation;
