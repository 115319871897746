import React, { memo, useMemo, useState } from 'react';
import AllocationCompare from 'components/AllocationCompare';
import { AllocationToogle } from 'components/AllocationToogle/AllocationToogle';
import PropTypes from 'prop-types';
import { ALLOCATION_TYPES, INV_ALLOC_DATA_KEYS } from 'constants/constants';
import { DEFAULT_TOGGLE_STATE, SHOW_TOGGLE } from 'constants/flt';

function AllocationCompareWithITLContainer({ data: investmentData, error, isLoading }) {
    const [withLookThroughtData, setWithLookThroughtData] = useState(DEFAULT_TOGGLE_STATE);

    const data = useMemo(
        () => investmentData[
            withLookThroughtData
                ? INV_ALLOC_DATA_KEYS.withLookThroughtData
                : INV_ALLOC_DATA_KEYS.withoutLookThroughtData
        ],
        [withLookThroughtData, investmentData],
    );
    const toogleHandler = () => {
        setWithLookThroughtData((prev) => !prev);
    };
    const currentAllocations = useMemo(() => data[ALLOCATION_TYPES.CURRENT_ALLOCATION], [data]);
    const newAllocations = useMemo(() => data[ALLOCATION_TYPES.NEW_ALLOCATION], [data]);

    return (
        <>
            <AllocationCompare
                data={currentAllocations}
                dataNew={newAllocations}
                isLoading={isLoading}
                error={error}
            />
            {(!error && !isLoading && SHOW_TOGGLE) && (
                <AllocationToogle
                    value={withLookThroughtData}
                    onChange={toogleHandler}
                />
            )}
        </>
    );
}

AllocationCompareWithITLContainer.propTypes = {
    data: PropTypes.shape({
        chart: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            data: PropTypes.shape({
                [PropTypes.string]: PropTypes.arrayOf(PropTypes.shape({
                    name: PropTypes.string,
                    value: PropTypes.number,
                })),
            }),
        })),
        currency: PropTypes.string,
    }),
    isLoading: PropTypes.bool,
    error: PropTypes.oneOf([PropTypes.bool, PropTypes.string]),
};

AllocationCompareWithITLContainer.defaultProps = {
    data: { chart: [] },
    isLoading: true,
    error: null,
};
export const AllocationCompareWithITL = memo(AllocationCompareWithITLContainer);
