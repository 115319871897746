import {
    useEffect, useMemo, useState, useCallback,
} from 'react';
import SM from 'services/ServiceManager';
import { useTranslation } from 'react-i18next';
import AdapterError from 'errors/AdapterError';
import { downloadDmsDocument } from 'utils/downloadDmsDocument';
import ServerError from 'errors/ServerError';
import { useProposals } from 'hooks/useProposals';
import { useFormatting } from 'locale';
import { adaptDocuments } from '../adapters/adaptDocument';
import { usePortfolios } from './usePortfolios';

export default ({
    clientId, currentPagination, tab, fileUploaded,
}) => {
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [isGeneralLoading, setGeneralLoading] = useState(true);
    const [data, setData] = useState([]);
    const [generalData, setGeneralData] = useState([]);
    const { t, i18n } = useTranslation();
    const { getFormattedDate } = useFormatting();
    const { data: proposals } = useProposals(clientId);
    const { getPortfolios } = usePortfolios({ clientId, proposals });
    const [pageSize, setPageSize] = useState(10);
    const [personalPaging, setPersonalPaging] = useState({});
    const [generalPaging, setGeneralPaging] = useState({});

    const personalParams = useMemo(() => ({
        documentTypeId: 1,
        isActive: true,
        sortBy: 'CreateDate',
        sortOrder: 'Descending',
        page: currentPagination,
        pageSize,
        personAssignment: {
            ids: [clientId],
            scope: 'Owner',
        },
    }), [currentPagination, fileUploaded]);

    const generalParams = useMemo(() => ({
        isActive: true,
        sortBy: 'CreateDate',
        sortOrder: 'Descending',
        page: currentPagination,
        pageSize,
        searchBy: 'IncludeShared',
        documentTypeId: 2,
    }), [currentPagination, fileUploaded]);

    const downloadDocument = useCallback((documentId) => {
        setError(null);
        SM.documents('getDocumentById', [documentId])
            .then((response) => {
                try {
                    if (response?.data) {
                        const { filename } = response.data;
                        const { newBlob } = downloadDmsDocument(
                            response.data,
                            {
                                name: filename,
                                saveFile: true,
                            },
                        );
                        const url = URL.createObjectURL(newBlob);

                        global.open(url, '_blank');
                    }
                } catch (err) {
                    throw new AdapterError(err);
                }
            })
            .catch((err) => {
                setError(err.type !== undefined ? err : new ServerError(err));
                setLoading(false);
            });
    }, []);

    const getDocuments = useCallback((docsParams) => new Promise((resolve, reject) => {
        SM.documents('postDocumentsList', [docsParams])
            .then((response) => resolve(response.data))
            .catch(reject);
    }), []);

    const handleDocumentsResponse = useCallback(async ({
        docResults,
        setDocs,
        setLoadingDocs,
        setPaging,
    }) => {
        const {
            page, pageSize: size, pageCount, totalCount,
        } = docResults;

        try {
            const userPortfolios = await getPortfolios({
                documents: docResults,
            });

            const adaptedData = adaptDocuments(
                docResults,
                downloadDocument,
                t,
                i18n,
                getFormattedDate,
                userPortfolios,
                tab,
            );

            setDocs(adaptedData);
            setLoadingDocs(false);
            setPageSize(size);
            setPaging({
                Page: page, PageSize: size, PageCount: pageCount, TotalCount: totalCount,
            });
        } catch (err) {
            throw new AdapterError(err);
        }
    }, [downloadDocument, t, getFormattedDate]);

    useEffect(() => {
        // Personal Docs
        if (tab === 'personal') {
            setLoading(true);
            getDocuments(personalParams)
                .then((resp) => {
                    handleDocumentsResponse({
                        docResults: resp,
                        setDocs: setData,
                        setLoadingDocs: setLoading,
                        setPaging: setPersonalPaging,
                    });
                })
                .catch((err) => {
                    setError(err.type !== undefined ? err : new ServerError(err));
                    setLoading(false);
                });
        }

        // General Docs
        if (tab === 'general') {
            setGeneralLoading(true);
            getDocuments(generalParams)
                .then((resp) => {
                    handleDocumentsResponse({
                        docResults: resp,
                        setDocs: setGeneralData,
                        setLoadingDocs: setGeneralLoading,
                        setPaging: setGeneralPaging,
                    });
                })
                .catch((err) => {
                    setError(err.type !== undefined ? err : new ServerError(err));
                    setGeneralLoading(false);
                });
        }
    }, [personalParams, generalParams, tab]);

    return {
        data,
        generalData,
        isLoading,
        isGeneralLoading,
        pageSize,
        personalPaging,
        generalPaging,
        error,
    };
};
