import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import { useLocale } from 'locale';
import ServerError from 'errors/ServerError';

export const useKnowledgeExperienceDetails = ({
    clientId,
}) => {
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const { i18n } = useTranslation();
    const { locale } = useLocale();

    const getProductAnswers = useCallback(async () => {
        if (!clientId) return null;

        setLoading(true);
        try {
            const params = { language: i18n.language };

            const { data: allPortfolios } = await ServiceManager.portfolioManagement('getAllPortfolios', [clientId, params]);

            const products = allPortfolios.map(
                (i) => ({
                    id: i.Product.Id,
                    name: i.Product.Name,
                    productOfferId: i.Product.ProductOfferId,
                }),
            )?.filter(
                (val, i, self) => self?.findIndex(
                    (j) => j?.id === val?.id
                        && j?.name === val?.name
                        && j?.productOfferId === val?.productOfferId,
                ) === i,
            );

            const dataQuestions = await Promise.all(products.map(async (i) => ({
                id: i.id,
                name: i.name,
                ...(await ServiceManager.compliance('searchQuestionnairesByContact', [clientId, {
                    Language: i18n.language,
                    IsComplete: true,
                    ProductId: i.id,
                    QuestionnaireTypeId: 2,
                }])).data,
            })));

            const productWithAnswers = dataQuestions.map((item) => ({
                productId: item?.id,
                productName: item?.name,
                answers: item?.Questionnaire?.Groups?.[0]?.Steps?.[0]?.Questions.map((i) => ({
                    Answers: i.Answers.map(
                        (answer) => (i.Value?.Ids.includes(answer.Id) ? answer : undefined),
                    ).filter((id) => id !== undefined),
                    Values: i.Value?.Ids,
                    Id: i.Id,
                    allAnswered: i.Answers?.length === i.Value?.Ids?.length,
                })),
            }));

            try {
                setData(productWithAnswers);
                setLoading(false);

                return data;
            } catch (err) {
                throw new AdapterError(err);
            }
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError,
                    setLoading,
                }),
            )(err);

            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, [i18n.language, locale, clientId]);

    useEffect(() => {
        getProductAnswers();
    }, [getProductAnswers, locale]);

    return { data, isLoading, error };
};
