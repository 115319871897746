import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import ServerError from 'errors/ServerError';
import { useFormatting } from 'locale';
import { useDetectedMobileDevice } from 'hooks/useDetectedMobileDevice';
import { adaptSecurityTransactions } from '../adapters/adaptSecurityTransactions';

export const useSecurityTransactions = (
    {
        currencyId,
        investmentAllocations,
        sourceAllocations,
        amount,
        portfolioCurrency,
        portfolioId,
        productID,
        clientId,
        actionType,
        proposalId,
        linkToOverview,
    },
) => {
    const [errorSescurityTransactions, setError] = useState(null);
    const [isLoadingSecurityTransactions, setLoading] = useState(true);
    const [securityTransactionsData, setData] = useState({});
    const { i18n, t } = useTranslation();
    const { isMobileSize } = useDetectedMobileDevice();
    const { getFormattedDate, getFormattedNumber, getFormattedCurrency } = useFormatting();

    useEffect(() => {
        setLoading(true);

        if (investmentAllocations === undefined || amount === undefined) {
            return;
        }

        const dataSimulate = {
            CurrencyId: currencyId,
            SourceAmount: amount,
            ProductId: productID,
            TargetInstrumentAllocations: investmentAllocations,
            SourceInstrumentAllocations: sourceAllocations,
        };

        ServiceManager.portfolioManagement('postPortfolioOrderbookSimulate', [
            clientId,
            { language: i18n.language },
            dataSimulate,
        ])
            .then((response) => {
                try {
                    setData(
                        adaptSecurityTransactions({
                            data: response.data,
                            isMobileSize,
                            portfolioId,
                            productID,
                            actionType,
                            proposalId,
                            t,
                            getFormattedDate,
                            getFormattedNumber,
                            getFormattedCurrency,
                            linkToOverview,
                        }),
                    );
                    setLoading(false);
                } catch (err) {
                    throw new AdapterError(err);
                }
            })
            .catch((err) => {
                setError(err.type !== undefined ? err : new ServerError(err));
                setLoading(false);
            });
    }, [productID, currencyId, investmentAllocations, amount, portfolioCurrency, i18n.language]);

    return { securityTransactionsData, isLoadingSecurityTransactions, errorSescurityTransactions };
};
