import { useCallback, useEffect, useReducer } from 'react';
import SM from 'services/ServiceManager';
import HandlerError from 'errors/HandlerError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import { useTranslation } from 'react-i18next';
import ServerError from '../../../errors/ServerError';

const initialState = {
    data: { Groups: [{ Steps: [{ Questions: [] }] }] },
    error: null,
    isLoading: false,
    errorSending: null,
    isSending: false,
    results: { Groups: [{ Steps: [{ Questions: [] }] }] },
    errorResults: null,
    isLoadingResults: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'setData':
            return { ...state, data: action.payload };
        case 'setError':
            return { ...state, error: action.payload };
        case 'setIsLoading':
            return { ...state, isLoading: action.payload };
        case 'setErrorSending':
            return { ...state, errorSending: action.payload };
        case 'setSending':
            return { ...state, isSending: action.payload };
        case 'setResults':
            return { ...state, results: action.payload };
        case 'setErrorResults':
            return { ...state, errorResults: action.payload };
        case 'setIsLoadingResults':
            return { ...state, isLoadingResults: action.payload };
        default:
            return state;
    }
};

const CONFIRM_DELAY = 1000;

export const useKnowledgeExperience = (options = {}) => {
    const {
        clientId,
        productId: productIdOption,
        questionnaireTypeId: questionnaireTypeIdOption = 2,
        loadInitially = false,
    } = (options || {});
    const [state, dispatch] = useReducer(reducer, initialState);
    const { i18n: { language } } = useTranslation();

    // Callbacks
    const getQuestionnaire = useCallback(async ({
        productId = productIdOption,
        questionnaireTypeId = questionnaireTypeIdOption,
    } = {}) => {
        dispatch({ type: 'setError', payload: null });
        dispatch({ type: 'setIsLoading', payload: true });

        const params = {
            Language: language,
            ProductId: productId,
            QuestionnaireTypeId: questionnaireTypeId,
        };

        try {
            const response = await SM.compliance('filterQuestionnaires', [params]);

            dispatch({ type: 'setData', payload: response?.data?.[0] });
            dispatch({ type: 'setIsLoading', payload: false });

            return response.data;
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setError', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setIsLoading', payload: val }),
                }),
            )(err);

            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, [language]);

    const sendKnowledgeExperience = useCallback((
        answers,
    ) => new Promise((resolve, reject) => {
        if (!answers) reject();
        dispatch({ type: 'setErrorSending', payload: null });
        dispatch({ type: 'setSending', payload: true });
        (async () => {
            try {
                await SM.compliance('postAnswers', [clientId, answers]);
                setTimeout(() => {
                    dispatch({ type: 'setSending', payload: false });
                    resolve();
                }, CONFIRM_DELAY);
            } catch (err) {
                handlerRequestCanceling(
                    HandlerError({
                        setError: (val) => dispatch({ type: 'setErrorSending', payload: val }),
                        setLoading: (val) => dispatch({ type: 'setSending', payload: val }),
                    }),
                )(err);
                reject();

                throw err.type !== undefined ? err : new ServerError(err);
            }
        })();
    }), [clientId]);

    const getQuestionnaireResults = useCallback(async ({
        productId = productIdOption,
        questionnaireTypeId = questionnaireTypeIdOption,
    } = {}) => {
        dispatch({ type: 'setErrorResults', payload: null });
        dispatch({ type: 'setIsLoadingResults', payload: true });

        const params = {
            Language: language,
            IsComplete: true,
            ProductId: productId,
            QuestionnaireTypeId: questionnaireTypeId,
        };

        try {
            const response = await SM.compliance('searchQuestionnairesByContact', [clientId, params]);

            dispatch({ type: 'setResults', payload: response?.data?.Questionnaire });
            dispatch({ type: 'setIsLoadingResults', payload: false });

            return response?.data?.Questionnaire;
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setErrorResults', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setIsLoadingResults', payload: val }),
                }),
            )(err);

            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, [clientId, language, productIdOption]);

    // Effects
    useEffect(() => {
        if (loadInitially) getQuestionnaire();
    }, [getQuestionnaire, loadInitially]);

    return {
        data: state.data,
        error: state.error,
        isLoading: state.isLoading,
        getQuestionnaire,
        isSending: state.isSending,
        errorSending: state.errorSending,
        sendKnowledgeExperience,
        results: state.results,
        errorResults: state.errorResults,
        isLoadingResults: state.isLoadingResults,
        getQuestionnaireResults,
    };
};
