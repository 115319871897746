import { BAR, LIST, PIE } from 'constants/constants';
import {
    allocationPercentFormat,
    formatPercentage,
} from 'utils/formatting';
import { checkNullData, roundArray } from 'utils';

import { sortAllocations } from 'utils/sortingAllocation';

const adaptChartTypes = (data, currency, getFormattedCurrency) => ({
    [PIE]: { data },
    [BAR]: {
        data: [
            {
                data: (data || []).map(({ value, color }) => ({ y: value, color })),
            },
        ],
        labels: (data || []).map(({ name }) => name),
    },
    [LIST]: {
        data: (data || []).map((item) => ({
            name: item.name,
            value: getFormattedCurrency(item.value, {
                currency,
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
            }),
            percent: allocationPercentFormat(item.percent),
            children: item.children,
        })),
    },
});

const getAllocationsGroups = (
    portfolioAllocations,
    assetClasses,
    securityTypes,
    currencies,
    countries,
    sectors,
    t,
    getFormattedCurrency,
    currencyPortfolio,
) => {
    const { Allocations } = portfolioAllocations;

    const result = {};

    const allocationsMapper = {
        ParentAssetClass: { field: 'asset', lookup: assetClasses },
        Country: { field: 'country', lookup: countries },
        Currency: { field: 'currency', lookup: currencies },
        ParentSector: { field: 'sector', lookup: sectors },
        Type: { field: 'type', lookup: securityTypes },
    };

    const getAllocationNameById = (category, id) => allocationsMapper[category].lookup.find(
        (el) => (el.Id === id) || (el.children && el.children.includes(id)),
    ).Name;

    const excludeInstrumentsFilter = (allocationGroup) => allocationGroup.Category !== 'Instrument';

    const roundedAllocations = Allocations.filter(excludeInstrumentsFilter)
        .map((allocation) => roundArray(allocation.Breakdowns.map(
            (breakdown) => breakdown.Allocation,
        )));

    Allocations.filter(excludeInstrumentsFilter).forEach((allocationGroup, groupIndex) => {
        if (Object.keys(allocationsMapper).includes(allocationGroup.Category)) {
            result[allocationsMapper[allocationGroup.Category].field] = {};
            allocationGroup.Breakdowns.forEach((allocationItem, breakdownIndex) => {
                result[allocationsMapper[allocationGroup.Category].field][allocationItem.Id] = {
                    amount: allocationItem.Amount,
                    id: allocationItem.Id,
                    name: getAllocationNameById(allocationGroup.Category, allocationItem.Id),
                    percentage: roundedAllocations[groupIndex][breakdownIndex],
                };
            });
        }
    });

    const groupArrays = Object.keys(result).map((groupKey) => {
        const group = result[groupKey];

        return sortAllocations(
            groupKey,
            Object.keys(group).map((key) => ({
                id: group[key].id,
                name: group[key].name,
                color: group[key].color,
                value: group[key].amount,
                percent: group[key].percentage,
                label: formatPercentage(group[key].percentage),
            })),
        );
    });

    return {
        chart: [
            {
                name: 'Asset Classes',
                title: t('allocationTabs.assetClasses'),
                data: adaptChartTypes(
                    groupArrays[3],
                    currencyPortfolio,
                    getFormattedCurrency,
                ),
            },
            {
                name: 'Type',
                title: t('allocationTabs.type'),
                data: adaptChartTypes(
                    groupArrays[2],
                    currencyPortfolio,
                    getFormattedCurrency,
                ),
            },
            {
                name: 'Currency',
                title: t('allocationTabs.currencies'),
                data: adaptChartTypes(
                    groupArrays[1],
                    currencyPortfolio,
                    getFormattedCurrency,
                ),
            },
            {
                name: 'Region',
                title: t('allocationTabs.regions'),
                data: adaptChartTypes(
                    groupArrays[0],
                    currencyPortfolio,
                    getFormattedCurrency,
                ),
            },
            {
                name: 'Sector',
                title: t('allocationTabs.sectors'),
                data: adaptChartTypes(
                    groupArrays[4],
                    currencyPortfolio,
                    getFormattedCurrency,
                ),
            },
        ],
        currency: currencyPortfolio,
    };
};

const traversalTree = (current, childrenArr) => {
    childrenArr.push(current.Data.Id);

    if (current.Children) {
        current.Children.forEach((child) => {
            traversalTree(child, childrenArr);
        });
    }
};

const flatArray = (array) => {
    const flatted = [];

    array.forEach((item) => {
        const { length } = flatted;

        flatted.push({ ...item.Data, children: [] });
        traversalTree(item, flatted[length].children);
    });

    return flatted;
};

export const adaptParentsAllocations = (
    portfolioAllocations,
    assetClasses,
    securityTypes,
    currency,
    countries,
    sectors,
    t,
    getFormattedCurrency,
    currencyPortfolio,
) => {
    if (checkNullData(portfolioAllocations)) {
        return { chart: [{ data: { pie: {}, bar: {}, list: {} } }] };
    }

    const flatSectors = flatArray(sectors);
    const flatAssetClasses = flatArray(assetClasses);

    return getAllocationsGroups(
        portfolioAllocations,
        flatAssetClasses,
        securityTypes,
        currency,
        countries,
        flatSectors,
        t,
        getFormattedCurrency,
        currencyPortfolio,
    );
};
