import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Button, Column, Infobox, Paragraph, Title, Row,
} from 'ui-library';
import {
    ADVISORY, DISCRETIONARY, EXECUTION_ONLY, generateObject, DEPOSITS,
} from 'constants/portfolioProducts';
import { useSelector } from 'react-redux';
import { useBenchmarks } from 'hooks/useBenchmarks';
import { useBankInfo } from 'hooks/useBankInfo';
import { contactIdSelector } from 'redux/auth/authSelectors';
import { onBoardingDataSelector, useOnBoardingSelector } from 'domain/OnBoarding';
import { useClientProfile } from 'prodivers/clientProfile';
import { useAdvisoryProtocol } from './hooks/useAdvisoryProtocol';
import { useInvestmentContract } from './hooks/useInvestmentContract';
import './Completion.css';
import OnBoardingBaseTemplate from '../../components/OnBoardingBaseTemplate';

const Completion = (props) => {
    const { onNext } = props;
    const { t } = useTranslation();
    const clientId = useSelector(contactIdSelector);
    const { isLoadingBankInfo, errorBankInfo, bankInfo } = useBankInfo({ contactId: clientId });
    // OnBoarding Domain
    const {
        clearRiskCategory,
        createdGoal,
        clearCreatedGoal,
        Iban,
        clearIban,
        clearData,
        ReviewData,
        getGoalChanges,
    } = useOnBoardingSelector(onBoardingDataSelector);

    // Data by product
    const goalChanges = useMemo(() => (!!Number.isInteger(+getGoalChanges())), [getGoalChanges]);
    const showBenchmark = useMemo(() => generateObject({
        [ADVISORY]: true,
        [DISCRETIONARY]: true,
        [EXECUTION_ONLY]: false,
        [DEPOSITS]: true,
    }).getByName(createdGoal.ProductName), [createdGoal.ProductName]);

    // Helpers hooks
    const {
        clientName, data: information, isLoading, error: errorInformation,
    } = useClientProfile();
    const {
        benchmarkSelected, isLoadingBenchmark,
    } = useBenchmarks({
        currencyId: createdGoal.CurrencyId,
        productId: createdGoal.ProductId,
        benchmarkId: ReviewData?.benchmarkId,
    }, false, showBenchmark);

    const params = {
        contactId: clientId,
        clientName,
        details: createdGoal,
        benchmarkSelected,
        BankBranchId: bankInfo.BankBranchId,
    };

    const {
        saveInvestmentContract, error, isLoading: isLoadingIC,
    } = useInvestmentContract({
        ...params, isStrategyChanged: goalChanges,

    });

    const {
        saveAdvisoryProtocol, isLoading: isLoadingProtocol, error: errorProtocol,
    } = useAdvisoryProtocol({
        ...params,
        questionnaireTypeId: 1,
    });


    // Callbacks
    const clearAndNext = () => {
        clearData();
        clearRiskCategory();
        clearCreatedGoal();
        clearIban();
        onNext();
    };

    return (
        <OnBoardingBaseTemplate
            title={t('onBoarding.congratulations')}
            error={errorInformation || errorBankInfo}
            isLoading={isLoading || isLoadingBenchmark || isLoadingBankInfo}
            className="completion"
            nextButton={{
                text: t('onBoarding.done'),
                onClick: clearAndNext,
            }}
        >
            <div className="completion-content">
                <Row>
                    <Column size="12">
                        <Paragraph type="secondary" className="completion-text">
                            {t('onBoarding.completionText')}
                        </Paragraph>
                    </Column>
                    <Column size="12">
                        <div className="group-title">
                            <Title type={3}>{t('onBoarding.bankDetails')}</Title>
                            <Paragraph type="secondary">
                                {t('onBoarding.bankDetailsText')}
                            </Paragraph>
                        </div>
                    </Column>
                </Row>
                <Row className="bank-info">
                    <Column size="6">
                        <div className="group-title">
                            <Title type={2}>{t('onBoarding.iban')}</Title>
                            <p className="bank-info-description">{Iban || ''}</p>
                        </div>
                    </Column>
                    <Column size="6">
                        <div className="group-title">
                            <Title type={2}>{t('onBoarding.bic')}</Title>
                            <p className="bank-info-description">{bankInfo.BIC}</p>
                        </div>
                    </Column>
                    <Column size="6">
                        <div className="group-title">
                            <Title type={2}>{t('onBoarding.beneficiary')}</Title>
                            <p className="bank-info-description">
                                {`${information?.FirstName} ${information?.LastName}`}
                            </p>
                            <p className="bank-info-description">
                                {information?.Street}
                                {' '}
                                {information?.StreetNumber}
                            </p>
                            <p className="bank-info-description">
                                {information?.PostalCode}
                                {' '}
                                {`${information?.City}, ${information?.State}`}
                            </p>
                            <p className="bank-info-description">
                                {information?.Country}
                            </p>
                        </div>
                    </Column>
                    <Column size="6">
                        <div className="group-title">
                            <Title type={2}>{t('onBoarding.bank')}</Title>
                            <p className="bank-info-description">{bankInfo.BankName}</p>
                            <p className="bank-info-description">
                                {bankInfo.BankAdress?.AddressLine1}
                                {' '}
                                {bankInfo.BankAdress?.AddressLine2}
                            </p>
                            <p className="bank-info-description">
                                {bankInfo.BankAdress?.AddressLine3}
                                {' '}
                                {bankInfo.BankAdress?.AddressLine4}
                            </p>
                            <p className="bank-info-description">
                                {bankInfo.BankAdress?.Country}
                            </p>
                        </div>
                    </Column>
                </Row>
                <Row className="documents-info">
                    <Column size="12">
                        <div className="group-title documents-title">
                            <Title type={3}>{t('onBoarding.documentsTitle')}</Title>
                            <Paragraph type="secondary">
                                {t('onBoarding.documentsText')}
                            </Paragraph>
                        </div>
                    </Column>
                    <Column size="12">
                        <div className="documents-button-group">
                            <Button type="secondary" size="small" loading={isLoadingIC} onClick={saveInvestmentContract}>
                                {t('onBoarding.downloadContact')}
                            </Button>
                            <Button type="secondary" size="small" loading={isLoadingProtocol} onClick={saveAdvisoryProtocol}>
                                {t('onBoarding.downloadAdvisoryProtocol')}
                            </Button>
                        </div>
                    </Column>
                </Row>
                {error && <Infobox error>{error?.message}</Infobox>}
                {errorProtocol && <Infobox error>{errorProtocol?.message}</Infobox>}
            </div>
        </OnBoardingBaseTemplate>
    );
};

Completion.propTypes = {
    onNext: PropTypes.func,
};

Completion.defaultProps = {
    onNext: () => {},
};

export default Completion;
