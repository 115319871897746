import React, { useEffect, useMemo, useState } from 'react';
import {
    ContentBox, Modal, Accordion, AccordionPanel as Panel, Infobox,
} from 'ui-library';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import history from 'services/history';
import Preloader from 'components/Preloader';
import { CHANGE_STRATEGY } from 'constants/constants';
import {
    orderBookSelector,
    portfolioSelector,
    usePortfolioSelector,
} from 'domain/Portfolio';
import Analysis from 'components/Analysis/Analysis';
import RiskDisclaimer from 'components/RiskDisclaimer';
import ButtonsBlockRow from 'components/ButtonsBlockRow';
import AccordionWrapper from 'components/AccordionWrapper';
import PageHeader from 'components/PageHeader';
import { useBenchmarks } from 'hooks/useBenchmarks';
import { useAnalysisDetails } from 'hooks/useAnalysisDetails';
import Positions from 'components/PositionsCompare';
import StrategyOverview from 'components/StrategyOverview';
import { useModelList } from 'hooks/useModelList';
import { useModelChange } from 'hooks/useModelChange';
import { useStrategyList } from 'hooks/useStrategyList';
import Allocation from 'components/AllocationCompare';
import { useCheckRiskProfile } from '../../../hooks/useCheckRiskProfile';
import { onPreventRedirect } from '../../common/utils';

function ChangeStrategyAdvisory(props) {
    const {
        contactId: clientId,
        match: { params: { portfolioId } },
    } = props;
    const { t } = useTranslation();
    const [strategy, setStrategy] = useState(JSON.parse(sessionStorage.getItem(CHANGE_STRATEGY)));
    const [errorSameStrategy, setErrorSameStrategy] = useState(false);

    // Portfolio Domain
    const {
        data, dataRaw, isLoading, error,
    } = usePortfolioSelector(portfolioSelector);
    const {
        data: orderBook, isLoading: isLoadingOrderBook, error: errorOrderBook, getOrderBook,
    } = usePortfolioSelector(orderBookSelector);

    useEffect(() => {
        getOrderBook();
    }, [getOrderBook]);

    // Hooks
    const baseUrl = `/portfolios/${portfolioId}/actions/change-strategy/position/${data?.productId}`;
    const {
        strategyList, isLoadingStrategyList, errorStrategyList,
    } = useStrategyList(data?.productId);
    const {
        data: modelList, isLoading: isLoadingModelList, error: errorModelList,
    } = useModelList({ productId: data?.productId, riskCategoryId: strategy });
    const {
        modelData, positions, modelId,
        isLoadingModelData, errorModelData, onModelChange, onModelReset,
    } = useModelChange({ portfolio: data, portfolioPositions: dataRaw?.Positions, baseUrl });
    const {
        benchmark, benchmarkOptions, benchmarkSelected, onBenchmarkChange, isLoadingBenchmark,
    } = useBenchmarks(data, isLoading, true);
    const strategyForAnalysis = useMemo(() => (modelData?.overview ? modelData
        : null), [modelData]);
    const {
        analysisData, isLoadingModel, errorModel, onPerformanceChange, performanceSelected,
    } = useAnalysisDetails({
        clientId,
        portfolio: data,
        strategy: strategyForAnalysis,
        positions,
        isLoading,
        benchmark,
        isLoadingBenchmark,
        actionType: CHANGE_STRATEGY,
    });

    useEffect(() => {
        if (modelList.length) {
            if (modelList.find(({ value }) => value === modelId)) return;
            onModelChange(modelList[0]?.value);
        }
    }, [modelList, modelId, onModelChange]);

    // Risk Profile Check
    const [showRiskDisclaimer, setShowRiskDisclaimer] = useState(false);
    const { checkRiskProfile } = useCheckRiskProfile(clientId);
    const { riskCategoryId, riskCategoryName } = useMemo(() => ({
        riskCategoryId: dataRaw?.RiskCategory?.Id,
        riskCategoryName: dataRaw?.RiskCategory?.Name,
    }), [dataRaw?.RiskCategory]);

    useEffect(() => {
        setShowRiskDisclaimer(false);
        if (modelData?.riskCategory) {
            setShowRiskDisclaimer(
                !checkRiskProfile(modelData.riskCategory, riskCategoryId),
            );
        }
    }, [checkRiskProfile, modelData?.riskCategory, riskCategoryId]);

    // Callbacks
    const onStrategyChange = (val) => {
        setStrategy(val);
        setErrorSameStrategy(false);
        sessionStorage.setItem(CHANGE_STRATEGY, JSON.stringify(val));
    };
    const onStrategyReset = () => {
        sessionStorage.removeItem(CHANGE_STRATEGY);
        onModelReset();
    };
    const onConfirm = () => {
        if (data?.modelPortfolioId === +modelId || !modelId) {
            setErrorSameStrategy(true);

            return;
        }

        if (!showRiskDisclaimer) {
            history.push(`/portfolios/${portfolioId}/actions/change-strategy/orders`);

            return;
        }

        Modal.confirm({
            title: t('overview.disclaimer'),
            content: t('changeStrategy.riskProfileContent'),
            okText: t('confirmation.yes'),
            cancelText: t('confirmation.no'),
            onOk: () => {
                history.push(`/portfolios/${portfolioId}/actions/change-strategy/orders`);
            },
            className: 'accept-risk-profile',
        });
    };
    const onCancel = (url) => {
        Modal.confirm({
            title: t('confirmation.discardChanges'),
            content: t('confirmation.discardChangesContent'),
            okText: t('confirmation.discardChanges'),
            onOk: () => {
                onStrategyReset();
                history.push(url);
            },
            cancelText: t('confirmation.cancel'),
            className: 'discard-changes',
            okType: 'danger',
        });
    };
    const onDiscardChanges = () => {
        onCancel(`/portfolios/${portfolioId}`);
    };

    // Renderers
    const AllocationRender = useMemo(
        () => (
            <Allocation
                data={data.investmentAllocation}
                dataNew={modelData?.investmentAllocation || data.investmentAllocation}
                isLoading={isLoading}
                error={error}
            />
        ),
        [
            data.investmentAllocation,
            modelData,
            isLoading,
            error,
        ],
    );
    const renderRiskDisclaimer = () => (
        <RiskDisclaimer PRP={modelData?.overview?.strategy} CRP={riskCategoryName} />
    );

    return (
        <Preloader
            isLoading={isLoadingOrderBook || isLoadingStrategyList}
            error={error || errorOrderBook || errorStrategyList}
        >
            <ContentBox className="change-strategy change-strategy-container with-mb" underline={false}>
                <PageHeader
                    title={t('overview.changeStrategy')}
                    breadCrumbsCurrent={t('overview.changeStrategy')}
                    breadCrumbsChildren={[
                        {
                            to: '/portfolios',
                            onClick: (e) => onPreventRedirect(e, '/portfolios', onCancel),
                            label: t('portfolios.title'),
                        },
                        {
                            to: `/portfolios/${portfolioId}`,
                            label: data?.title,
                            onClick: (e) => onPreventRedirect(e, `/portfolios/${portfolioId}`, onCancel),
                        },
                    ]}
                />
                {showRiskDisclaimer && !isLoading && renderRiskDisclaimer()}
                <div className="mb-0 mt-5 overview">
                    <StrategyOverview
                        data={data.overview}
                        isLoading={isLoadingStrategyList}
                        error={errorStrategyList}
                        isLoadingModel={isLoadingModelList}
                        errorModel={errorModelList}
                        selectedStrategy={strategy}
                        strategyList={strategyList}
                        onStrategyChange={onStrategyChange}
                        selectedModel={modelId}
                        modelList={modelList}
                        onModelChange={onModelChange}
                    />
                </div>
                <Preloader isLoading={isLoadingModelData} error={errorModelData}>
                    <AccordionWrapper className="confirmation">
                        <Accordion defaultActiveKey={['1', '2', '3']}>
                            <Panel header={t('confirmation.allocation')} key="1" className="allocation">
                                {AllocationRender}
                            </Panel>
                            <Panel header={t('confirmation.analysis')} key="2" className="analysis">
                                <Analysis
                                    data={analysisData}
                                    onFilterChange={onPerformanceChange}
                                    benchmarkOptions={benchmarkOptions}
                                    benchmarkSelected={benchmarkSelected}
                                    onBenchmarkChange={onBenchmarkChange}
                                    isLoadingBenchmarks={isLoadingBenchmark}
                                    performanceSelected={performanceSelected}
                                    isLoading={isLoadingModel}
                                    error={errorModel}
                                />
                            </Panel>
                            <Panel header={t('confirmation.positions')} className="positions" key="3">
                                <Positions
                                    data={positions}
                                    isLoading={isLoading}
                                    defaultExpandAllRows
                                />
                            </Panel>
                        </Accordion>
                    </AccordionWrapper>
                </Preloader>
                <ButtonsBlockRow
                    leftButton={{
                        type: 'danger',
                        text: t('confirmation.cancel'),
                        onClick: onDiscardChanges,
                    }}
                    primaryButton={{
                        text: t('confirmation.continue'),
                        loading: isLoadingModelData,
                        disabled: orderBook,
                        onClick: onConfirm,
                    }}
                >
                    {errorSameStrategy && <Infobox error>{t('onBoarding.errorChangeSameStrategy')}</Infobox>}
                </ButtonsBlockRow>
            </ContentBox>
        </Preloader>
    );
}

ChangeStrategyAdvisory.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            portfolioId: PropTypes.string.isRequired,
        }),
        path: PropTypes.string,
    }).isRequired,
    contactId: PropTypes.number.isRequired,
};

ChangeStrategyAdvisory.defaultProps = {};

export default ChangeStrategyAdvisory;
