import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    TabPane, Tabs, Table, PieChart,
} from 'ui-library';
import Preloader from 'components/Preloader';
import EmptyContent from 'components/EmptyContent';
import { getInstComponentColumns } from '../constants';
import { adaptTableComponents } from '../adapters/adaptTableComponents';

export const FundLookThrough = ({
    data, isLoading, error, changePage,
}) => {
    const { t } = useTranslation();
    const pageSize = 5;
    const onChangePage = (pageInfo) => {
        if (data?.components.currentPage !== pageInfo) {
            changePage(pageInfo.currentPage);
        }
    };

    const adaptedComponentsData = useMemo(() => adaptTableComponents({
        data: data?.components?.tableData,
        columns: getInstComponentColumns(t),
        pageSize,
        isLoading,
    }), [isLoading, data?.components?.tableData]);

    return (
        <div>
            <Tabs
                className="fund-lookthrough"
                defaultActiveKey="1"
                style={{
                    overflow: 'visible',
                }}
            >
                <TabPane tab={t('position.component')} key="1" className="components-tab flt-tab">
                    <EmptyContent data={data?.components?.tableData} text={t('clientDashboard.portfolioEdit.noDataFound')}>
                        <Table
                            expandedColumn="name"
                            className="components-table"
                            data={adaptedComponentsData}
                            columns={getInstComponentColumns(t)}
                            paging={{
                                total: data?.components?.totalPages || 0,
                                pageSize,
                                currentPage: data?.components.currentPage,
                            }}
                            onChange={onChangePage}
                            defaultExpandAllRows
                        />
                    </EmptyContent>
                </TabPane>

                <TabPane tab={t('position.assetClass')} key="2" className="asset-classes-tab flt-tab">
                    <Preloader isLoading={isLoading} error={error}>
                        <EmptyContent data={data?.assetClass} text={t('clientDashboard.portfolioEdit.noDataFound')}>
                            <PieChart data={data?.assetClass} hideValues />
                        </EmptyContent>
                    </Preloader>
                </TabPane>

                <TabPane tab={t('position.type')} key="3" className="type-tab flt-tab">
                    <Preloader isLoading={isLoading} error={error}>
                        <EmptyContent data={data?.type} text={t('clientDashboard.portfolioEdit.noDataFound')}>
                            <PieChart data={data?.type} hideValues />
                        </EmptyContent>
                    </Preloader>
                </TabPane>

                <TabPane tab={t('position.currency')} key="4" className="currencies-tab flt-tab">
                    <Preloader isLoading={isLoading} error={error}>
                        <EmptyContent data={data?.currency} text={t('clientDashboard.portfolioEdit.noDataFound')}>
                            <PieChart data={data?.currency} hideValues />
                        </EmptyContent>
                    </Preloader>
                </TabPane>

                <TabPane tab={t('position.region')} key="5" className="region-tab flt-tab">
                    <Preloader isLoading={isLoading} error={error}>
                        <EmptyContent data={data?.country} text={t('clientDashboard.portfolioEdit.noDataFound')}>
                            <PieChart data={data?.country} hideValues />
                        </EmptyContent>
                    </Preloader>
                </TabPane>

                <TabPane tab={t('position.sector')} key="6" className="sector-tab flt-tab">
                    <Preloader isLoading={isLoading} error={error}>
                        <EmptyContent data={data?.sector} text={t('clientDashboard.portfolioEdit.noDataFound')}>
                            <PieChart data={data?.sector} hideValues />
                        </EmptyContent>
                    </Preloader>
                </TabPane>
            </Tabs>
        </div>
    );
};

FundLookThrough.propTypes = {
    data: PropTypes.shape({
        components: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                currency: PropTypes.string,
                assetClass: PropTypes.string,
                sector: PropTypes.string,
                weight: PropTypes.string,
            }),
        ),
        assetClass: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
                value: PropTypes.number,
                allocation: PropTypes.number,
            }),
        ),
        country: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
                value: PropTypes.number,
                allocation: PropTypes.number,
            }),
        ),
        currency: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
                value: PropTypes.number,
                allocation: PropTypes.number,
            }),
        ),
        sector: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
                value: PropTypes.number,
                allocation: PropTypes.number,
            }),
        ),
        type: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
                value: PropTypes.number,
                allocation: PropTypes.number,
            }),
        ),

    }),
    error: PropTypes.shape({
        message: PropTypes.string,
    }),
    isLoading: PropTypes.bool,
    changePage: PropTypes.func,
};

FundLookThrough.defaultProps = {
    data: {
        components: [],
        assetClass: [],
        country: [],
        currency: [],
        sector: [],
        type: [],
    },
    error: null,
    isLoading: false,
    changePage: () => null,
};
