import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
    ContentBox, Modal, Accordion, AccordionPanel as Panel,
} from 'ui-library';
import history from 'services/history';
import { useTranslation } from 'react-i18next';
import Analysis from 'components/Analysis';
import ButtonsBlockRow from 'components/ButtonsBlockRow';
import Preloader from 'components/Preloader';
import {
    portfolioModifySelector, portfolioSelector, tradingCardSelector, usePortfolioSelector,
} from 'domain/Portfolio';
import { contactIdSelector } from 'redux/auth/authSelectors';
import PageHeader from 'components/PageHeader';
import { connect } from 'react-redux';
import AccordionWrapper from 'components/AccordionWrapper';
import { useBenchmarks } from 'hooks/useBenchmarks';
import { useAnalysisDetails } from 'hooks/useAnalysisDetails';
import Positions from 'components/PositionsCompare';
import StrategyOverview from 'components/StrategyOverview';
import { mapActionTypeName } from 'constants/instrument';
import AllocationCompare from 'components/AllocationCompare';
import { MODIFY } from '../../constants';
import { EXECUTION_ONLY } from '../../../common/constants';
import { onPreventRedirect } from '../../../common/utils';
import { useModify } from '../../hooks/useModify';
import { useCheckRiskProfile } from '../../../../hooks/useCheckRiskProfile';
import RiskBandwidthDisclaimer from '../../../../components/RiskBandwidthDisclaimer';
import './ModifyConfirmation.css';


function ModifyConfirmation(props) {
    const {
        contactId: clientId,
        match: { params: { portfolioId } },
    } = props;
    const { t } = useTranslation();

    const roundingAllocationData = (data) => {
        if (!data) return;
        let sum = 0;
        let maxValue = data[0].Allocation;

        data.forEach((el) => {
            sum += el.Allocation;
            if (maxValue < el.Allocation) {
                maxValue = el.Allocation;
            }
        });

        const difference = 1 - sum;

        if (difference > 0) {
            return data.map((el) => {
                if (maxValue === el.Allocation) {
                    return {
                        ...el,
                        Allocation: parseFloat((el.Allocation + difference)
                            .toFixed(4)),
                    };
                }

                return el;
            });
        }
    };

    // Portfolio Domain
    const {
        data, dataRaw, isLoading, error,
    } = usePortfolioSelector(portfolioSelector);
    const {
        deleteTradingCard, isDeleting, errorDeleting,
    } = usePortfolioSelector(tradingCardSelector);
    const {
        dataRaw: dataTradingCard, isLoading: isLoadingTradingCard, error: errorTradingCard,
        getModifiedPositions,
    } = usePortfolioSelector(portfolioModifySelector);


    const portfolioRequestPayload = useMemo(() => ({
        ...data,
        investmentAllocations: roundingAllocationData(data?.investmentAllocations),
    }), [data]);

    useEffect(() => {
        getModifiedPositions();
    }, [getModifiedPositions]);

    const showStrategy = useMemo(() => (
        data?.overview
            ? data?.overview?.product !== EXECUTION_ONLY
            : true
    ), [data?.overview]);

    // Hooks
    const baseUrl = `/portfolios/${portfolioId}/actions/modify/position/${data?.productId}`;
    const { positions, modelData } = useModify({
        portfolio: data,
        portfolioPositions: dataRaw?.Positions,
        positionsModified: dataTradingCard?.OrderBookEntries,
        baseUrl,
    });

    const strategyRequestPayload = useMemo(() => ({
        ...modelData,
        investmentAllocations: roundingAllocationData(modelData?.investmentAllocations),
        projection: {
            ...modelData?.projection,
            InstrumentAllocations: roundingAllocationData(modelData?.projection
                .InstrumentAllocations),
        },
        investmentAllocationsRound: roundingAllocationData(modelData?.investmentAllocationsRound),
    }), [modelData?.investmentAllocationsRound, modelData?.investmentAllocations]);

    const {
        benchmark, benchmarkOptions, benchmarkSelected, onBenchmarkChange, isLoadingBenchmark,
    } = useBenchmarks(data, isLoading, true);
    const {
        analysisData, isLoading: isLoadingAnalysis, isLoadingModel, errorModel,
        onPerformanceChange, performanceSelected, volatility,
    } = useAnalysisDetails({
        clientId,
        portfolio: portfolioRequestPayload,
        strategy: strategyRequestPayload,
        positions,
        isLoading,
        benchmark,
        isLoadingBenchmark,
        actionType: MODIFY,
    });

    // Risk Profile Check
    const [showRiskDisclaimer, setShowRiskDisclaimer] = useState(false);
    const {
        compareVolatility, clientRiskBandwidth,
    } = useCheckRiskProfile(clientId, data.productId);
    const { riskCategoryId, riskCategoryName } = useMemo(() => ({
        riskCategoryId: dataRaw?.RiskCategory?.Id,
        riskCategoryName: dataRaw?.RiskCategory?.Name,
    }), [dataRaw?.RiskCategory]);

    useEffect(() => {
        setShowRiskDisclaimer(false);

        if (volatility) {
            compareVolatility(volatility, riskCategoryId).then((passed) => {
                setShowRiskDisclaimer(!passed);
            });
        }
    }, [compareVolatility, volatility, riskCategoryId]);

    // Renderers
    const AllocationRender = useMemo(
        () => (
            <AllocationCompare
                data={data.investmentAllocation}
                dataNew={modelData?.investmentAllocation || data.investmentAllocation}
                isLoading={isLoading}
                error={error}
            />
        ),
        [
            data.investmentAllocation,
            modelData,
            isLoading,
            error,
        ],
    );
    const renderRiskDisclaimer = () => (
        <RiskBandwidthDisclaimer
            risk={volatility}
            min={clientRiskBandwidth?.Min}
            max={clientRiskBandwidth?.Max}
            CRP={riskCategoryName}
        />
    );

    // Callbacks
    const onCancel = (link) => {
        Modal.confirm({
            title: t('confirmation.discardChanges'),
            content: t('confirmation.discardChangesContent'),
            okText: t('confirmation.discardChanges'),
            onOk: () => {
                deleteTradingCard(clientId, portfolioId);
                sessionStorage.removeItem(MODIFY);
                history.push(link);
            },
            cancelText: t('confirmation.cancel'),
            className: 'discard-changes',
            okType: 'danger',
        });
    };
    const onDiscardChanges = () => {
        history.push(`/portfolios/${portfolioId}/actions/modify`);
    };

    const onConfirm = () => {
        if (!showRiskDisclaimer) {
            history.push(`/portfolios/${portfolioId}/actions/modify/orders`);

            return;
        }

        Modal.confirm({
            title: t('overview.disclaimer'),
            content: t('changeStrategy.riskProfileContent'),
            okText: t('confirmation.yes'),
            cancelText: t('confirmation.no'),
            onOk: () => {
                history.push(`/portfolios/${portfolioId}/actions/modify/orders`);
            },
            className: 'accept-risk-profile',
        });
    };

    return (
        <Preloader
            isLoading={isLoading || isLoadingTradingCard}
            error={error || errorTradingCard || errorDeleting}
        >
            <ContentBox className="confirmation-page with-mb" underline={false}>
                <PageHeader
                    title={t('confirmation.title')}
                    breadCrumbsCurrent={t('confirmation.title')}
                    breadCrumbsChildren={[
                        {
                            to: '/portfolios',
                            onClick: (e) => onPreventRedirect(e, '/portfolios', onCancel),
                            label: t('portfolios.title'),
                        },
                        {
                            to: `/portfolios/${portfolioId}`,
                            onClick: (e) => onPreventRedirect(e, `/portfolios/${portfolioId}`, onCancel),
                            label: data?.title,
                        },
                        {
                            to: `/portfolios/${portfolioId}/actions/modify`,
                            label: mapActionTypeName(MODIFY, t),
                        },
                    ]}
                />
                {showRiskDisclaimer && renderRiskDisclaimer()}
                <div className="overview">
                    <StrategyOverview
                        data={data.overview}
                        dataStrategy={data.overview}
                        isLoading={isLoading}
                        error={error}
                        showStrategy={showStrategy}
                    />
                </div>
                <AccordionWrapper className="confirmation">
                    <Accordion defaultActiveKey={['1', '2', '3', '4']}>
                        <Panel header={t('confirmation.allocation')} key="1" className="allocation">
                            {AllocationRender}
                        </Panel>
                        <Panel header={t('confirmation.analysis')} key="2" className="analysis">
                            <Analysis
                                data={analysisData}
                                onFilterChange={onPerformanceChange}
                                benchmarkOptions={benchmarkOptions}
                                benchmarkSelected={benchmarkSelected}
                                onBenchmarkChange={onBenchmarkChange}
                                isLoadingBenchmarks={isLoadingBenchmark}
                                performanceSelected={performanceSelected}
                                isLoading={isLoadingModel}
                                error={errorModel}
                            />
                        </Panel>
                        <Panel header={t('confirmation.positions')} className="positions" key="3">
                            <Positions
                                data={positions}
                                isLoading={isLoading}
                                error={error}
                                defaultExpandAllRows
                            />
                        </Panel>
                    </Accordion>
                </AccordionWrapper>
                <ButtonsBlockRow
                    leftButton={{
                        text: t('confirmation.back'),
                        loading: isDeleting,
                        onClick: onDiscardChanges,
                    }}
                    primaryButton={{
                        text: t('confirmation.continue'),
                        loading: isDeleting,
                        disabled: isLoadingAnalysis,
                        onClick: onConfirm,
                    }}
                />
            </ContentBox>
        </Preloader>
    );
}

ModifyConfirmation.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            portfolioId: PropTypes.string.isRequired,
        }),
        url: PropTypes.string,
        path: PropTypes.string,
    }).isRequired,
    contactId: PropTypes.number.isRequired,
};

ModifyConfirmation.defaultProps = {
};

const mapStateToProps = (state) => ({
    contactId: contactIdSelector(state),
});

export default connect(mapStateToProps)(ModifyConfirmation);
