import {
    useCallback,
    useEffect,
    useState,
    useContext,
} from 'react';
import SM from 'services/ServiceManager';
import ServerError from 'errors/ServerError';
import HandlerError from 'errors/HandlerError';
import { gql, useLazyQuery } from '@apollo/client';
import { getLocaleString } from 'locale/utils';
import { useFormatting } from 'locale';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import { CommonAllocationContext } from 'pages/pages/Portfolios/context/CommonAllocationDataProvider';
import { groupObjectById } from 'adaptors/groupObjectById';
import { CommonAllocationContext as CAContextOnBoarding } from '../../OnBoarding/context/CommonAllocationDataProvider';

const SEC_QUERY = gql`
    query Security(
        $take: Int!
        $skip: Int!
        $order: [SecuritySortInput!]!
        $where: SecurityFilterInput!
    ) {
        pagedSecurities(take: $take, skip: $skip, order: $order, where: $where) {
            pageInfo {
                hasNextPage
                hasPreviousPage
            }
            items {
                id
                name
                sector {
                    name
                }
                assetClass {
                    name
                }
                currency {
                    name
                }
            }
        }
    }
`;

const AllocationsCategory = {
    assetClass: 'ParentAssetClass',
    type: 'Type',
    currency: 'Currency',
    country: 'Country',
    sector: 'ParentSector',
};

const initialAllocationSec = {
    assetClass: [],
    type: [],
    currency: [],
    country: [],
    sector: [],
};

const initialComponentSec = {
    tableData: [],
    totalPages: 0,
};

export const useFundLookThrough = ({
    instrumentId,
    portfolioId,
    productId,
    tabId,
    language,
    listSize = 5,
    isOnboarding,
}) => {
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [componentSec, setComponentSec] = useState(initialComponentSec);
    const [allocationSec, setAllocationSec] = useState(initialAllocationSec);
    const { getFormattedNumber } = useFormatting();
    const [currentPage, setCurrentPage] = useState(1);

    // const {
    //     assetClasses, countries, currencies, sectors, securityTypes,
    // } = useContext(CommonAllocationContext);

    const {
        assetClasses, countries, currencies, sectors, securityTypes,
    } = useContext(
        isOnboarding ? CAContextOnBoarding : CommonAllocationContext,
    );

    const secQueryParams = {
        take: listSize,
        skip: 0,
        order: [{ id: 'ASC' }],
        where: {
            id: { in: [] },
        },
    };
    const [
        getSecurities, { fetchMore: getSecuritiesPage },
    ] = useLazyQuery(SEC_QUERY, { variables: secQueryParams });

    const getSecurityData = useCallback(
        async (variables) => {
            if (componentSec?.tableData?.length === 0) {
                return getSecurities(variables);
            }

            return getSecuritiesPage(variables);
        }, [componentSec],
    );

    const findCategoryBreakdowns = (key, allocation) => (
        allocation.find((item) => (item.category === AllocationsCategory[key]))?.breakdowns || []
    );

    const findAllocationName = (itemId, commonData) => {
        const groupedData = groupObjectById(commonData);

        return Object.values(groupedData).find((item) => (
            item.children.includes(itemId)
        ))?.name;
    };

    const componentUrlBuilder = useCallback((securityId) => (
        portfolioId
            ? `/portfolios/${portfolioId}/position/${productId}/${securityId}/${tabId}/overview`
            : `${securityId}`
    ), [portfolioId, productId, tabId]);

    const allocationTabsNames = useCallback(
        (allocationTabs) => {
            const {
                assetClass,
                type,
                currency,
                country,
                sector,
            } = allocationTabs;

            const returnData = [
                {
                    allocation: assetClass,
                    common: assetClasses,
                },
                {
                    allocation: type,
                    common: securityTypes,
                },
                {
                    allocation: currency,
                    common: currencies,
                },
                {
                    allocation: country,
                    common: countries,
                },
                {
                    allocation: sector,
                    common: sectors,
                },
            ].map(({ allocation, common }) => (
                allocation.map((item) => ({
                    ...item,
                    id: item.id,
                    value: item.allocation * 100,
                    name: findAllocationName(item.id, common),
                }))
            ));

            return {
                assetClass: returnData[0],
                type: returnData[1],
                currency: returnData[2],
                country: returnData[3],
                sector: returnData[4],
            };
        },
        [assetClasses, securityTypes, currencies, countries, sectors],
    );

    const getSecAllocations = useCallback(
        async (securityId) => {
            try {
                const {
                    data: { categoriesBreakdowns: allocations },
                } = await SM.instrumentsService('getSecAllocations', [securityId]);

                if (allocations) {
                    const allocationTabs = {
                        assetClass: findCategoryBreakdowns('assetClass', allocations),
                        type: findCategoryBreakdowns('type', allocations),
                        currency: findCategoryBreakdowns('currency', allocations),
                        country: findCategoryBreakdowns('country', allocations),
                        sector: findCategoryBreakdowns('sector', allocations),
                    };

                    const allocationWithNames = allocationTabsNames(allocationTabs);

                    setAllocationSec(allocationWithNames);
                    setLoading(false);

                    return allocationWithNames;
                }

                return initialAllocationSec;
            } catch (err) {
                const allocError = err.type !== undefined ? err : new ServerError(err);

                // handle server response for allocations w/o components or breakdowns
                if (allocError?.error?.response?.status === 422) {
                    setLoading(false);

                    return initialAllocationSec;
                }

                handlerRequestCanceling(
                    HandlerError({ setError, setLoading }),
                )(err);

                throw allocError;
            }
        },
        [allocationTabsNames, findCategoryBreakdowns],
    );

    const getComponents = useCallback(
        async (securityId, page) => {
            try {
                setLoading(true);
                const { data: { components } } = await SM.instrumentsService('getSecComponents', [securityId]);

                if (!components || components.length === 0) {
                    setLoading(false);

                    return [];
                }

                const componentIds = components.map((item) => item.id);
                const {
                    data: { pagedSecurities: { items: compColumns } },
                } = await getSecurityData({
                    variables: {
                        ...secQueryParams,
                        take: listSize,
                        skip: page ? ((page - 1) * listSize) : 0,
                        where: {
                            id: { in: componentIds },
                        },
                    },
                });

                const tableData = compColumns.map((item) => {
                    const secWeight = components.find((comp) => comp.id === item.id);
                    const roundedWeight = secWeight?.allocation
                        ? getFormattedNumber(secWeight.allocation * 100)
                        : 0;

                    return {
                        name: {
                            label: item?.name,
                            link: componentUrlBuilder(item?.id),
                        },
                        sector: getLocaleString(item?.sector?.name, language),
                        assetClass: getLocaleString(item?.assetClass?.name, language),
                        currency: getLocaleString(item?.currency?.name, language),
                        weight: `${roundedWeight}%`,
                        weightvalue: secWeight.allocation,
                    };
                });

                setComponentSec({
                    tableData,
                    totalPages: componentIds.length,
                });
                setLoading(false);

                return tableData;
            } catch (err) {
                handlerRequestCanceling(
                    HandlerError({ setError, setLoading }),
                )(err);

                throw err.type !== undefined ? err : new ServerError(err);
            }
        },
        [language, getSecuritiesPage, getSecurityData, listSize],
    );

    const changePage = (page) => setCurrentPage(page);

    const getFundLookthroughData = async (securityId, page) => {
        const { assetClass } = await getSecAllocations(securityId);

        if (assetClass.length > 0) getComponents(securityId, page);
    };

    useEffect(() => {
        if (assetClasses && countries && currencies && sectors && securityTypes) {
            getFundLookthroughData(instrumentId, currentPage);
        }
    }, [assetClasses, countries, currencies, sectors, securityTypes, language, currentPage]);

    return {
        data: {
            ...allocationSec,
            components: {
                ...componentSec,
                currentPage,
            },
        },
        isLoading,
        error,
        changePage,
    };
};
