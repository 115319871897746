/* eslint-disable import/no-cycle */
import { reset, SubmissionError } from 'redux-form';
import Cookies from 'universal-cookie';
import { get } from 'lodash/fp';
import ServiceManager from 'services/ServiceManager';
import Security from 'additiv-services/dist/services/core/security';
import { SUCCESS } from 'static/requestResults';
import { FORM_NAME } from 'pages/pages/Login/components/LoginForm/LoginForm';
import {
    getErrorModelByStatus,
    getReduxErrorByStatus,
    LOGIN_FAILED_UNEXPECTEDLY,
} from 'error/authErrors';
import history from 'services/history';
import { contactIdSelector, jwtAccessTokenSelector } from './authSelectors';
import { LOGGED_IN } from '../../constants/constants';

export const LOGIN_SUCCESS = 'react-template-project/auth/LOGIN_SUCCESS';
export const LOGOUT_SUCCESS = 'react-template-project/auth/LOGOUT_SUCCESS';
export const GET_USER_PROFILE_START = 'react-template-project/auth/GET_USER_PROFILE_START';
export const GET_USER_PROFILE_SUCCESS = 'react-template-project/auth/GET_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILE_FAIL = 'react-template-project/auth/GET_USER_PROFILE_FAIL';
export const PRE_AUTH_WITH_2FA_COMPLETE = 'react-template-project/auth/PRE_AUTH_WITH_2FA_COMPLETE';
export const LOGIN_WITHOUT_2FA_SUCCESS = 'react-template-project/auth/LOGIN_WITHOUT_2FA_SUCCESS';
export const PRE_AUTH_SUCCESS = 'react-template-project/auth/PRE_AUTH_SUCCESS';
export const PRE_AUTH_CAPTCHA_SUCCESS = 'react-template-project/auth/PRE_AUTH_CAPTCHA_SUCCESS';
export const AUTH_COMPLETE = 'react-template-project/auth/AUTH_COMPLETE';
export const RENEW_ACCESS_TOKEN = 'react-template-project/auth/RENEW_ACCESS_TOKEN';
export const RENEW_ACCESS_TOKEN_PROCESS_STARTED = 'react-template-project/auth/RENEW_ACCESS_TOKEN_PROCESS_STARTED';
export const REMEMBER_ME = 'react-template-project/auth/REMEMBER_ME';

export const RESET_LOGIN = 'react-template-project/auth/RESET_LOGIN';

const cookies = new Cookies();

export function loginWithout2FASuccess(payload) {
    return { type: LOGIN_WITHOUT_2FA_SUCCESS, payload };
}

export function preAuthWith2FAComplete() {
    return { type: PRE_AUTH_WITH_2FA_COMPLETE };
}

export function authComplete(payload) {
    return { type: AUTH_COMPLETE, payload };
}

export function renewAccessToken(payload) {
    return { type: RENEW_ACCESS_TOKEN, payload };
}

export function renewAccessTokenProcessStarted() {
    return { type: RENEW_ACCESS_TOKEN_PROCESS_STARTED };
}

export function loginSuccess(payload) {
    return { type: LOGIN_SUCCESS, payload };
}

export function getUserProfileStart() {
    return { type: GET_USER_PROFILE_START };
}

export function getUserProfileSuccess(payload) {
    return { type: GET_USER_PROFILE_SUCCESS, payload };
}

export function getUserProfileFail() {
    return { type: GET_USER_PROFILE_FAIL };
}

export function logoutSuccess() {
    return { type: LOGOUT_SUCCESS };
}

export function preAuthComplete(payload) {
    return { type: PRE_AUTH_SUCCESS, payload };
}

export function preAuthCaptchaComplete(payload) {
    return { type: PRE_AUTH_CAPTCHA_SUCCESS, payload };
}

export function rememberMyDevice(payload) {
    return { type: REMEMBER_ME, payload };
}

export function resetLogin() {
    return { type: RESET_LOGIN };
}

export function getUserProfile() {
    return (dispatch, getState) => {
        const state = getState();
        const contactId = contactIdSelector(state);

        dispatch(getUserProfileStart());

        return new Promise((resolve, reject) => {
            ServiceManager.contactManagement('getContactMembers', [contactId])
                .then(({ data: membersResponse }) => {
                    dispatch(
                        getUserProfileSuccess({
                            members: membersResponse?.advisors,
                        }),
                    );
                    resolve({ members: membersResponse?.advisors });
                })
                .catch((reason) => {
                    const errorModel = getErrorModelByStatus(LOGIN_FAILED_UNEXPECTEDLY);

                    dispatch(getUserProfileFail(errorModel));
                    reject(reason);
                });
        });
    };
}

export const LOGIN_WITH_URL_SUCCESS = 'react-template-project/auth/LOGIN_WITH_URL_SUCCESS';
export const LOGIN_WITH_URL_START = 'react-template-project/auth/LOGIN_WITH_URL_START';

export function loginWithURLSuccess() {
    return { type: LOGIN_WITH_URL_SUCCESS };
}

export function loginWithURLStart(payload) {
    return { type: LOGIN_WITH_URL_START, payload };
}

export function loginByURL({ urlContactId, urlAccessToken }) {
    return (dispatch) => new Promise((resolve) => {
        dispatch(loginWithURLStart({ contactId: urlContactId, jwt: urlAccessToken }));
        dispatch(getUserProfile()).then(() => {
            dispatch(loginWithURLSuccess());
            resolve();
        });
    });
}

export const SESSION_CHECKED = 'react-template-project/session/SESSION_CHECKED';

export function sessionChecked(payload) {
    return { type: SESSION_CHECKED, payload };
}

export function checkSession() {
    return (dispatch, getState) => {
        const state = getState();
        const jwtAccessToken = jwtAccessTokenSelector(state);

        return new Promise((resolve) => {
            const [promise] = ServiceManager.Security('validateToken', [{
                payload: { AccessToken: jwtAccessToken },
            }]);

            promise
                .then((response) => {
                    const resultType = get('data.Result', response);

                    dispatch(
                        sessionChecked({
                            isCurrentSessionChecked: true,
                            isSessionValid: resultType === 'Success',
                        }),
                    );
                    resolve();
                })
                .catch(() => {
                    dispatch(
                        sessionChecked({
                            isCurrentSessionChecked: false,
                            isSessionValid: false,
                        }),
                    );

                    resolve();
                });
        });
    };
}

export function login2FACaptcha({
    username, password, rememberMe, captchaToken,
}, dispatch) {
    return new Promise((resolve, reject) => {
        const trustedDeviceToken = cookies.get('trustedDeviceToken');

        ServiceManager.Security('preAuthWithCaptcha', [{
            payload: {
                username,
                password,
                forceExpiringPreviousSession: true,
                trustedDeviceToken,
                captchaToken,
            },
        }])
            .then((preAuthResponse) => {
                const resultType = get('data.result', preAuthResponse);
                const errorObject = getReduxErrorByStatus(resultType);

                if (errorObject !== undefined) {
                    reject(new SubmissionError(errorObject));
                } else if (resultType === SUCCESS) {
                    const isTwoFactorEnabled = get(
                        'data.campaignContact.isTwoFactorEnabled',
                        preAuthResponse,
                    );
                    const session = get('data.session', preAuthResponse);

                    dispatch(
                        preAuthCaptchaComplete({
                            ...preAuthResponse.data,
                            username,
                            rememberMe,
                        }),
                    );
                    if (isTwoFactorEnabled === false || session !== undefined) {
                        sessionStorage.setItem(LOGGED_IN, '1');
                        dispatch(reset(FORM_NAME));
                        dispatch(
                            authComplete({
                                sessionId: get('data.session.sessionId', preAuthResponse),
                                jwtAccessToken: get('data.session.jwtAccessToken', preAuthResponse),
                            }),
                        );
                        dispatch(reset(FORM_NAME));
                        resolve();
                        dispatch(getUserProfile());
                    } else {
                        dispatch(preAuthWith2FAComplete());
                        dispatch(reset(FORM_NAME));
                        resolve();
                    }
                } else {
                    reject(new SubmissionError(
                        getReduxErrorByStatus(LOGIN_FAILED_UNEXPECTEDLY),
                    ));
                }
            })
            .catch(() => {
                const errorObject = getReduxErrorByStatus(LOGIN_FAILED_UNEXPECTEDLY);

                reject(new SubmissionError(errorObject));
            });
    });
}

export function login2FA({ username, password, rememberMe }, dispatch) {
    return new Promise((resolve, reject) => {
        const trustedDeviceToken = cookies.get('trustedDeviceToken');

        ServiceManager.Security('preAuth', [{
            payload: {
                UserName: username,
                Password: password,
                ForceExpiringPreviousSession: true,
                TrustedDeviceToken: trustedDeviceToken,
            },
        }]).then((preAuthResponse) => {
            const resultType = get('data.Result', preAuthResponse);
            const errorObject = getReduxErrorByStatus(resultType);

            if (errorObject !== undefined) {
                reject(new SubmissionError(errorObject));
            } else if (resultType === SUCCESS) {
                const isTwoFactorEnabled = get(
                    'data.CampaignContact.IsTwoFactorEnabled',
                    preAuthResponse,
                );
                const session = get('data.Session', preAuthResponse);

                dispatch(
                    preAuthComplete({
                        ...preAuthResponse.data,
                        username,
                        rememberMe,
                    }),
                );
                if (isTwoFactorEnabled === false || session !== undefined) {
                    sessionStorage.setItem(LOGGED_IN, '1');
                    dispatch(reset(FORM_NAME));
                    dispatch(
                        authComplete({
                            sessionId: get('data.Session.SessionId', preAuthResponse),
                            jwtAccessToken: get('data.Session.JwtAccessToken', preAuthResponse),
                        }),
                    );
                    dispatch(reset(FORM_NAME));
                    resolve();
                    dispatch(getUserProfile());
                } else {
                    dispatch(preAuthWith2FAComplete());
                    dispatch(reset(FORM_NAME));
                    resolve();
                }
            } else {
                reject(new SubmissionError(
                    getReduxErrorByStatus(LOGIN_FAILED_UNEXPECTEDLY),
                ));
            }
        })
            .catch(() => {
                const errorObject = getReduxErrorByStatus(LOGIN_FAILED_UNEXPECTEDLY);

                reject(new SubmissionError(errorObject));
            });
    });
}

export function status2FASuccess(data) {
    return (dispatch) => {
        const trustedDeviceToken = get('TrustedDeviceToken', data);

        if (trustedDeviceToken !== undefined && trustedDeviceToken !== null) {
            const date = new Date();
            const expirationDays = process.env.REACT_APP_COOKIES_EXPIRES_IN_DAYS;

            date.setTime(date.getTime() + expirationDays * 24 * 60 * 60 * 1000);
            cookies.set('trustedDeviceToken', trustedDeviceToken, {
                path: '/',
                expires: date,
            });
        }
        dispatch(
            authComplete({
                sessionId: get('Session.SessionId', data),
                jwtAccessToken: get('Session.JwtAccessToken', data),
            }),
        );
        dispatch(getUserProfile());
        dispatch(reset(FORM_NAME));
    };
}

export const RESET2FA = 'react-template-project/auth/RESET2FA';
export function reset2FA() {
    return { type: RESET2FA };
}

export function logout() {
    return async (dispatch, getState) => {
        const state = getState();
        const contactId = contactIdSelector(state);

        try {
            await Security.invalidateAllAccessTokens();
        } catch (e) {
            console.error('Member access token has already expired', e);
        }
        if (contactId) {
            try {
                await ServiceManager.Security('logOff', [{ urlParams: { contactId } }]);
            } catch (e) {
                console.error('Unsuccessful logout', e);
            }
        }
        global.sessionStorage.removeItem('auth');
        dispatch(logoutSuccess());
        history.push({
            pathname: '/login',
        });
    };
}
