import { getLocaleString } from 'locale/utils';
import { downloadDmsDocument } from '../utils/downloadDmsDocument';
import { stripHtmlTags } from '../utils';

export const adaptInstrumentDocs = (options) => {
    const { data, positionId, language } = (options || {});
    const t = options?.t || ((d) => d);
    const getDocumentById = options?.getDocumentById || (() => {});
    const getFormattedDate = options?.getFormattedDate || ((d) => d);
    const downloadDocument = (id) => {
        getDocumentById({
            documentId: id,
            instrumentId: positionId,
        }).then((response) => {
            const { file, filename } = response;

            if (file) downloadDmsDocument(response, { name: filename, saveFile: true });
        });
    };

    return (data?.results || []).map((item) => ({
        id: item?.id,
        key: `instrument-document-${item?.id}`,
        name: {
            value: item?.filename || item?.retrieveFileAsName || item?.documentMetadata?.name,
            onDownload: downloadDocument,
        },
        type: stripHtmlTags(
            getLocaleString(item?.documentMetadata?.documentCategory?.name, language),
        ),
        issuer: t('position.issuerBank'),
        release: getFormattedDate(item?.createDate),
        icon: {
            type: 'save',
            onClickIcon: () => downloadDocument(item?.id),
        },
    }));
};
