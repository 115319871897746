import { useCallback, useMemo, useReducer } from 'react';
import { sum } from 'utils';
import { NA } from 'utils/formatting';
import SM from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import ServerError from 'errors/ServerError';
import { useLocale } from 'locale';
import { parseLocaleNumber } from 'locale/utils';
import { optimizeQuickEdit } from '../adapters/optimizeQuickEdit';

const initialState = {
    adaptData: (data) => data,
    dataRaw: [],
    changes: [],
    changesManual: [],
    changesNonManual: [],
    dataSummary: {},
    risk: {},
    errorRisk: null,
    isLoadingRisk: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'setAdaptData':
            return { ...state, adaptData: action.payload };
        case 'setDataRaw':
            return { ...state, dataRaw: action.payload };
        case 'setChanges':
            return { ...state, changes: action.payload };
        case 'setChangesManual':
            return { ...state, changesManual: action.payload };
        case 'setChangesNonManual':
            return { ...state, changesNonManual: action.payload };
        case 'setDataSummary':
            return { ...state, dataSummary: action.payload };
        case 'setRisk':
            return { ...state, risk: action.payload };
        case 'setErrorRisk':
            return { ...state, errorRisk: action.payload };
        case 'setIsLoadingRisk':
            return { ...state, isLoadingRisk: action.payload };
        default:
            return state;
    }
};

const getDecimals = (value) => Number((`${value}`).split('.')[1] || 0);

export const usePortfolioQuickEdit = () => {
    const { locale } = useLocale();
    const [state, dispatch] = useReducer(reducer, initialState);

    // Callbacks
    const onAllocationChange = useCallback((manually) => (Id) => (eValue) => {
        const value = eValue?.target ? eValue.target.value : eValue;
        const {
            changes, changesManual, changesNonManual, dataSummary,
        } = state;
        const changesObj = changes.reduce((acc, item) => ({ ...acc, [item.Id]: item }), {});
        const valueNumeric = ((manually ? parseLocaleNumber(value, locale) : value) || 0)
            .toFixed(2);
        const newPosition = {
            Id, Allocation: +valueNumeric, CurrencyId: changesObj[Id]?.CurrencyId,
        };
        const changesNew = changes.map((item) => (item.Id === Id ? newPosition : item));

        dispatch({ type: 'setChanges', payload: changesNew });
        dispatch({ type: 'setDataSummary', payload: { ...dataSummary, New: sum(changesNew, 'Allocation') } });

        if (manually) {
            const indexManual = changesManual.findIndex((item) => item.id === Id);
            const dataChangedManual = indexManual !== -1
                ? [
                    ...changesManual.slice(0, indexManual),
                    newPosition,
                    ...changesManual.slice(indexManual + 1),
                ]
                : [...changesManual, newPosition];

            dispatch({ type: 'setChangesManual', payload: dataChangedManual });
        } else {
            const indexNonManual = changesNonManual.findIndex((item) => item.Id === Id);
            const dataChangedNonManual = indexNonManual !== -1
                ? [
                    ...changesNonManual.slice(0, indexNonManual),
                    newPosition,
                    ...changesNonManual.slice(indexNonManual + 1),
                ]
                : [...changesNonManual, newPosition];

            dispatch({ type: 'setChangesNonManual', payload: dataChangedNonManual });
        }
    }, [state]);
    const onIncrease = useCallback((Id) => {
        const oldValue = +state.changes.find((item) => item.Id === Id)?.Allocation;

        if (oldValue === 100) return;
        if (getDecimals(oldValue) === 0) {
            onAllocationChange(false)(Id)(oldValue + 1);
        } else {
            onAllocationChange(false)(Id)(Math.ceil(oldValue));
        }
    }, [onAllocationChange, state.changes]);
    const onDecrease = useCallback((Id) => {
        const oldValue = +state.changes.find((item) => item.Id === Id)?.Allocation;

        if (oldValue === 0) return;
        if (getDecimals(oldValue) === 0) {
            onAllocationChange(false)(Id)(oldValue - 1);
        } else {
            onAllocationChange(false)(Id)(Math.floor(oldValue));
        }
    }, [onAllocationChange, state.changes]);
    const onRemove = useCallback((Id) => {
        const {
            dataRaw, changes, changesManual, changesNonManual, dataSummary,
        } = state;
        const dataRawNew = dataRaw.filter((item) => (item.Security?.Id || item?.Id) !== Id);
        const changesNew = changes.filter((item) => item.Id !== Id);
        const changesManualNew = changesManual.filter((item) => item.Id !== Id);
        const changesNonManualNew = changesNonManual.filter((item) => item.Id !== Id);

        dispatch({ type: 'setDataRaw', payload: dataRawNew });
        dispatch({ type: 'setDataSummary', payload: { ...dataSummary, New: sum(changesNew, 'Allocation') } });
        dispatch({ type: 'setChanges', payload: changesNew });
        dispatch({ type: 'setChangesManual', payload: changesManualNew });
        dispatch({ type: 'setChangesNonManual', payload: changesNonManualNew });

        return {
            dataRaw: dataRawNew,
            changes: changesNew,
        };
    }, [onAllocationChange, state.changes]);
    const initializePositions = useCallback((
        { positions, changes, adaptData } = {},
    ) => {
        const adaptFunc = typeof adaptData === 'function' ? adaptData : (data) => data;
        const changesToSave = changes || positions.map((item) => ({
            Id: item?.Id || item?.Security?.Id,
            Allocation: item.Allocation,
            CurrencyId: item.CurrencyId,
        }));

        dispatch({ type: 'setAdaptData', payload: adaptFunc });
        dispatch({ type: 'setDataRaw', payload: positions });
        dispatch({ type: 'setChanges', payload: changesToSave });
        if (changes) dispatch({ type: 'setChangesNonManual', payload: changes });
        dispatch({
            type: 'setDataSummary',
            payload: {
                Old: sum(positions, 'Allocation'),
                New: sum(changesToSave, 'Allocation'),
            },
        });
    }, []);
    const optimizePositions = useCallback(({
        positions = state.changes,
        total = state.dataSummary.New,
    } = {}) => {
        const { dataSummary } = state;
        const optimized = optimizeQuickEdit(positions, total);

        dispatch({ type: 'setChanges', payload: optimized });
        dispatch({ type: 'setChangesNonManual', payload: optimized });
        dispatch({ type: 'setDataSummary', payload: { ...dataSummary, New: sum(optimized, 'Allocation') } });

        return optimized;
    }, [state]);
    const getRisk = useCallback(async ({ currencyId, positions, filters } = {}) => {
        dispatch({ type: 'setErrorRisk', payload: null });
        dispatch({ type: 'setIsLoadingRisk', payload: true });

        if (!positions?.length || !currencyId || currencyId === NA) return null;

        try {
            const params = {
                InstrumentSet: {
                    CurrencyId: currencyId,
                    Allocations: positions,
                    AllocationType: 'Percentage',
                },
                ...filters,
            };
            const response = await SM.performance('calculateKPIPerformance', [params]);

            try {
                dispatch({ type: 'setRisk', payload: response?.data });
                dispatch({ type: 'setIsLoadingRisk', payload: false });

                return response?.data;
            } catch (err) {
                throw new AdapterError(err);
            }
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setErrorRisk', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setIsLoadingRisk', payload: val }),
                }),
            )(err);

            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, []);


    // Computed values
    const positionsAdapted = useMemo(() => state.adaptData({
        positions: state.dataRaw,
        changes: state.changes,
        changesManual: state.changesManual,
        changesNonManual: state.changesNonManual,
        dataSummary: state.dataSummary,
        onChange: onAllocationChange(true),
        onIncrease,
        onDecrease,
    }), [state, onAllocationChange, onIncrease, onDecrease]);

    return {
        initializePositions,
        positionsAdapted,
        adaptData: state.adaptData,
        dataRaw: state.dataRaw,
        changes: state.changes,
        changesManual: state.changesManual,
        changesNonManual: state.changesNonManual,
        dataSummary: state.dataSummary,
        onAllocationChange,
        onIncrease,
        onDecrease,
        onRemove,
        optimizePositions,
        getRisk,
    };
};
