module.exports = {
    customProperties: {
        '--primary-default': '#000',
        '--primary-lighter': '#555555',
        '--primary-light': '#555555',
        '--primary-dark': '#000',
        '--primary-darker': '#000',
        '--secondary-default': '#555555',
        '--secondary-lighter': '#000',
        '--secondary-light': '#555555',
        '--secondary-dark': '#000',
        '--secondary-darker': '#000',
        '--background-base': '#fff',
        '--background-content': '#fff',
        '--content-box-shadow': '0 0 0 1px #555555',
        '--btn-secondary-disabled-color-theme': '#FFF',
        '--infobox-bg-theme': '#000',
        '--infobox-color-theme': '#FFF',
        '--infobox-border-color-theme': '#000',
        '--infobox-accent-bg-theme': '#000',
        '--infobox-accent-color-theme': '#FFF',
        '--language-select-btn-color': '#fff',
        '--language-select-btn-border-color': '#555555',
        '--language-select-button-border-color-base': '#555555',
        '--language-select-button-focused-bg': '#000',
        '--language-select-dd-border': '#555555',
        '--language-select-dropdown-bg': '#000',
        '--language-select-option-wrapper-color': '#FFF',
        '--table-row-selected-bg-theme': '#555555',
        '--table-row-selected-color-theme': '#fff',
        '--datepicker-weekend-color-theme': '#000',
        '--datepicker-weekend-bg-color-theme': '#555555',
        '--bandwidth-chart-plot-color-theme': '#e4e8ee',
        '--bandwidth-chart-color-0-theme': '#25282e',
        '--bandwidth-chart-color-3-theme': '#9dabbf',
        '--bar-chart-color-4-theme': '#9dabbf',
        '--line-chart-colors-3-theme': '#25282e',
        '--pie-chart-colors-0-theme': '#25282e',
        '--pie-chart-colors-3-theme': '#c3ccd9',
        '--pie-chart-colors-6-theme': '#9dabbf',
        '--pie-chart-single-fill-theme': '#e4e8ee',
        '--projection-chart-color-4-theme': '#9dabbf',
        '--projection-chart-color-5-theme': '#c3ccd9',
        '--projection-chart-color-6-theme': '#25282e',
        '--projection-chart-stroke-3-theme': '#25282e',
        '--projection-chart-legend-external-color-theme': '#9dabbf',
        '--projection-chart-positiveNegative-color-2-theme': '#e4e8ee',
        '--projection-chart-positiveNegative-color-3-theme': '#e4e8ee',
        '--projection-chart-positiveNegative-color-4-theme': '#e4e8ee',
        '--projection-chart-positiveNegative-color-5-theme': '#e4e8ee',
        '--projection-chart-positiveNegative-color-6-theme': '#9dabbf',
        '--projection-chart-positiveNegative-color-7-theme': '#9dabbf',
        '--projection-chart-positiveNegative-stroke-2-theme': '#25282e',
        '--projection-chart-positiveNegative-stroke-3-theme': '#25282e',
        '--risk-return-chart-color-2-theme': '#9dabbf',
        '--risk-return-axis-stroke-theme': '#c3ccd9',
        '--risk-return-grid-stroke-theme': '#e4e8ee',
        '--btn-primary-bg': '#ff0000',

        '--accent-default': '#ff0000',
        '--accent-lighter': '#ff3333',
        '--accent-light': '#ff6666',
        '--accent-dark': '#e60000',
        '--accent-darker': '#cc0000',
        '--positive-default': '#079464',
        '--positive-lighter': '#09c585',
        '--positive-light': '#0df4a5',
        '--positive-dark': '#067c54',
        '--positive-darker': '#056343',
        '--negative-default': '#de0000',
        '--negative-lighter': '#ff1212',
        '--negative-light': '#ff4545',
        '--negative-dark': '#c50000',
        '--negative-darker': '#ab0000',
        '--footer-bottom-color-theme': '#ffffff',
    },
};
